@use "sass:map";

.banners_hero {
  .container-fluid {
    max-width: 1380px;
  }
  .hero_desktop_slider, .hero_mobile_slider {
    .owl-carousel {
      padding: 0px;

      .owl-nav {
        button.owl-prev, button.owl-next {
          position: absolute;
          margin-top: 0px;

          .icon {
            color: #ffffff;

            &:before {
              font-size: 15px;
              font-weight: 800;
            }
          }
        }
        button.owl-prev {
          left: 15px;
        }
        button.owl-next {
          right: 15px;
        }
      }
      .owl-dots {
        display: flex;
        gap: 1px;

        .owl-dot {
          flex: 1;
          height: 6px;
          background: rgba(255, 255, 255, .3);
          transition: all .3s;

          span {
            display: none;
          }
          &.active, &:hover {
            background: #EDEDED;
          }
        }
      }
    }
    .item {
      .banner-fill-link {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
      }
      .banner_img {
        aspect-ratio: 360 / 243;
        background-size: cover;
        background-position: center;
      }
      .title_wrp {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        a {
          padding: 20px 25px;

          &:hover {
            text-decoration: none;
          }
        }
        .vcenter {
          display: flex;
          height: 100%;
          align-items: center;

          &.ha-left {
            justify-content: flex-start;
          }
          &.ha-center {
            justify-content: center;
          }
          &.ha-right {
            justify-content: flex-end;
            text-align: right;

            .banner_content {
              h1, .title {
                &:after {
                  margin-left: auto;
                }
              }
            }
          }
          &.va-top {
            align-items: flex-start;
          }
          &.va-middle {
            align-items: center;
          }
          &.va-bottom {
            align-items: flex-end;
          }
          &:before {
            display: none;
          }
        }
        .banner_content {
          max-width: 400px;
          white-space: initial;

          h1, .title {
            margin: 10px 0px 10px 0px;
            font-size: 20px;
            line-height: 24px;
          }
          .desc {
            margin-bottom: 15px;
            font-weight: 300;
            font-size: 13px;
            line-height: 22px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .hero_desktop_slider, .hero_mobile_slider {
      text-align: center;
    }
  }
  @include media-breakpoint-up(md) {
    .hero_desktop_slider, .hero_mobile_slider {
      .owl-carousel {
        .owl-nav {
          button.owl-prev, button.owl-next {
            .icon {
              &:before {
                font-size: 25px;
              }
            }
          }
          button.owl-prev {
            left: 50px;
          }
          button.owl-next {
            right: 50px;
          }
        }
        .owl-dots {
          gap: 3px;

          .owl-dot {
            height: 13px;
          }
        }
      }
      .item {
        .banner_img {
          aspect-ratio: 1380 / 713;
        }
        .title_wrp {
          a {
            padding: 87px 100px;
          }
          .banner_content {
            h1, .title {
              margin: 1em 0px 34px 0px;
              font-size: 38px;
              line-height: 38px;
            }
            .desc {
              margin-bottom: 32px;
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}