@use "sass:map";

@import "components/header";
@import "components/breadcrumb";
@import "components/cookie-bar";
@import "components/footer";
@import "components/page_editor";

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: $font-family-base;
  font-size: $font-size-base;
  background-color: $body-bg;
  color: $gray-800;

  &.main-nav-active {
    position: fixed;
    width: 100%;
  }
}

#content_layout {
  #content_wrapper {
    padding-top: 135px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      padding-top: 60px;
    }

    .content_body {
      padding-top: 0;

      .col-context_menu {
        // desktop
        @media (min-width: map.get($grid-breakpoints, "lg")) {
          float: right;
        }
      }
    }
  }
}

div.content_layout {
  background: $gray-100;
}

.content_layout_index {
  h2 {
    margin-top: 2em;
    margin-bottom: 1.2em;
    font-weight: 500;
    text-transform: uppercase;
  }
}