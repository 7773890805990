@use "sass:map";

.linepromos {
  z-index: $z-index-special-case;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 5px 15px !important;
    padding: .3125rem;
    text-align: center;

    p {
      margin-bottom: 0px;
    }

    @media screen and (min-width: map.get($grid-breakpoints, "md")) {
      padding: 8px 15px !important;
      padding: .5rem;
    }
  }

  .owl-carousel {
    .owl-stage {
      display: flex;
      flex-direction: row;

      &:after {
        display: none;
      }

      .eowl-item {
        float: none;

        &:before, &:after {
          //content: " ";
          //display: table;
        }
      }
    }

    .owl-nav button {
      &.owl-prev, &.owl-next {
        margin-top: 0px;
        padding: 5px !important;
        background: none;
        color: $white;


        &:hover {
          background: none !important;
        }
      }
    }
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
  }
}