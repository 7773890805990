.page_editor_template {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  padding-right: 28px;
  max-width: 1380px;

  &.full_width {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;

    .row, .product_listing {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 78px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page_editor_banner_right {
  > div {
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;

    .modal-body & {
      flex-direction: column;
    }
  }
}
.page_editor_banner_top {
  @include media-breakpoint-down(sm) {
    > div {
      &.banner-wrp {
        order: 1;
      }
      &.texts-wrp {
        order: 2;
      }
    }
  }
}
.page_editor_banner_bottom {
  @include media-breakpoint-down(sm) {
    > div {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
    }
  }
}

.editor_type_text {
  &.editor_type_title_block {
    margin-bottom: 47px;
    padding: 21px 28px 30px 28px;
    font-weight: 300;

    h1, .h1 {
      margin-bottom: 14px;
    }
  }
  @include media-breakpoint-down(md) {
    h2, .h2, h3, .h3 {
      text-align: left;
    }
  }
  @include media-breakpoint-up(md) {
    &.editor_type_title_block {
      margin-bottom: 75px;
      padding: 26px 28px 34px 28px;

      h1, .h1 {
        margin-bottom: 19px;
      }
    }
  }
}

.editor_type_banner {
  margin-bottom: 61px;
  padding: 0px;

  a {
    &:hover {
      text-decoration: none;
    }
  }
  .banner-inner-wrp {
    display: flex;
    align-items: center;
    padding: 28px;
    min-height: 222px;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;

    .banner-content-wrp {
      max-width: 70%;

      h2 {
        margin: 0px;
        margin-bottom: 10px;
        line-height: 30px;
        text-transform: initial;
        letter-spacing: 0.326068px;
      }
      p {
        margin-bottom: 12px;
      }
      button {
        margin-bottom: 15px;
        padding: 7px 0px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.13em;
        color: #000000;
        background-color: transparent;
        border: none;
        box-shadow: inset 0 0.15em transparent, inset 0 -0.15em #000000;
      }
      &.fonts-1 {
        h2 {
          margin-bottom: 5px;
          font-size: 28px;
          line-height: 30px;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }

    &.ha-left {
      justify-content: flex-start;
    }
    &.ha-center {
      justify-content: center;
    }
    &.ha-right {
      justify-content: flex-end;
    }
  }
  &.ha-left {
    .banner-inner-wrp {
      background-position: left center;
    }
  }
  &.ha-center {
    .banner-inner-wrp {
      background-position: center;
    }
  }
  &.ha-right {
    .banner-inner-wrp {
      background-position: right center;
    }
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 55px;

    .banner-inner-wrp {
      padding: 70px;
      min-height: 540px;

      .banner-content-wrp {
        h2 {
          margin-bottom: 18px;
          font-size: 70px;
          line-height: 74px;
          letter-spacing: 0.793605px;
        }
        p {
          margin-bottom: 20px;
          font-weight: 300;
          font-size: 17px;
          line-height: 28px;
        }
        button {
          margin-bottom: 0px;
          padding: 4px 0px;
          font-size: 13px;
          line-height: 22px;
        }
        &.fonts-1 {
          h2 {
            margin-bottom: 19px;
            font-size: 50px;
            line-height: 48px;
            letter-spacing: 0px;
          }
          p {
            font-size: 14px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
.editor_type_banner_text  {
  .inner-wrp {
    display: flex;
    flex-direction: column;

    a {
      &:hover {
        text-decoration: none;
      }
    }
    > div {
      flex-shrink: 0;
      width: 100%;
    }
    .banner-wrp {
      display: flex;
      justify-content: center;
      overflow: hidden;
      height: 290px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &.shadow {
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3) !important;
      }
    }
    .texts-wrp {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 42px 32px;
      text-align: center;

      h2 {
        margin-top: 0px;
        margin-bottom: 23px;
        text-transform: initial;
      }
      .text-wrp {
        margin-bottom: 24px;
      }
      &.fonts-1 {
        line-height: 22px;
        font-size: 15px;
      }
      &.margin-tb {
        margin: 0px 8px;
        width: calc(100% - 16px);
      }
    }
  }
  @include media-breakpoint-down(md) {
    padding-left: 14px;
    padding-right: 14px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 54px;
    padding: 0px;

    .inner-wrp {
      flex-direction: row;

      > div {
        flex-shrink: 0;
        width: 50%;
      }
      .banner-wrp {
        height: auto;

        &.wide {
          width: 58.3%;
        }
      }
      .texts-wrp {
        padding: 142px 32px;
        justify-content: center;
        text-align: left;

        .text-column {
          max-width: 390px;
        }
        .text-wrp {
          margin-bottom: 30px;
        }
        &.fonts-1 {
          line-height: 30px;
          font-size: 17px;
        }
        &.margin-tb {
          margin: 28px 0px;
          width: 41.7%;
        }
      }
    }
  }
}

.editor_type_features  {
  overflow: hidden;
  margin-top: -39px;
  margin-bottom: 25px;
  padding: 40px;

  .inner-wrp {
    .carousel-inner-x {
      display: flex;
      flex-direction: row;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 28px 28px 30px 28px;
        min-height: 217px;
        width: 100%;
        text-align: center;
        background-color: #F9F9FB;
        transition: all .3s;

        .img-wrp {
          margin-bottom: 10px;

          img {
            margin: 0px auto;
            height: 40px;
            width: auto;
          }
        }
        h3 {
          margin-bottom: 0px;
          font-weight: normal;
          font-size: 20px;
          line-height: 23px;
        }
        p {
          margin-bottom: 0px;
          font-size: 14.189px;
          line-height: 18px;
        }
      }
    }
    .owl-carousel {
      .owl-stage-outer {
        overflow: visible;

        .owl-item {
          &.active {
            z-index: 1;

            .item {
              background-color: #FFFFFF;
              box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
            }
          }
        }
      }
      .owl-dots {
        bottom: 11px;
        .owl-dot {
          span {
            margin: 5px 4px;
            height: 8px;
            width: 8px;
            background: #C4C4C4;
            border: 1px solid #ffffff;
            transition: all .3s;
          }
          &.active {
            span {
              background: #000000;
              border-color: #000000;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: 40px 0px;

    .inner-wrp {
      gap: 3px;

      .carousel-inner-x {
        .item {
          min-height: 416px;
          width: calc(100% / 3);

          .img-wrp {
            margin-bottom: 21px;

            img {
              height: 68px;
            }
          }
          h3 {
            margin-bottom: 3px;
            font-size: 22px;
            line-height: 26px;
          }
          p {
            font-size: 17px;
            line-height: 22px;
          }
          &.active {
            background-color: #FFFFFF;
            box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
          }
        }
      }
    }
  }
}

.editor_type_banner_banner {
  padding: 0px;

  a {
    &:hover {
      text-decoration: none;
    }
  }
  .inner-wrp {
    display: flex;
    flex-direction: column;

    .banner-wrp {
      .banner-inner-wrp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 28px;
        min-height: 280px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        h3 {
          margin: 0px 0px 12px 0px;
          font-weight: normal;
          font-size: 28px;
          line-height: 24px;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {

    .inner-wrp {
      flex-direction: row;

      .banner-wrp {
        width: 50%;

        .banner-inner-wrp {
          min-height: 564px;
        }
      }
    }
  }
}

.editor_type_collection {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: -22px;
  padding: 0px 40px 40px 40px;

  .collections_index_listing {
    max-width: 250px;

    .collection {
      h2 {
        margin-top: 0px;
        margin-bottom: 33px;
        text-transform: initial;
      }
      .product_listing {
        .product_element {
          padding: 0px 1px;

          > a {
            padding: 30px 0px 49px 0px;
            width: 225px;
            background: #F5F5F9;
            transition: all .3s;
          }
          .title {
            margin-top: 0px;
            margin-bottom: 30px;
            height: auto;

            h3 {
              margin-top: 0px;
              margin-bottom: 5px;
              font-weight: normal;
              font-size: 20px;
              line-height: 25px;
            }
            p {
              margin-bottom: 0px;
              font-weight: 300;
              font-size: 13px;
              line-height: 14px;
            }
          }
        }
        .owl-item.center {
          z-index: 1;

          .product_element {
            > a {
              background: #ffffff;
              box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
            }
          }
        }
      }
      .owl-carousel {
        .owl-stage-outer {
          overflow: visible;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 80px;

    .collections_index_listing {
      max-width: 100%;

      .collection {
        .product-slider-container {
          position: relative;
          max-width: 1225px;
          margin: 0px auto !important;
          padding: 0px 30px;
        }
        h2 {
          margin-bottom: 12px;
          font-size: 38px;
          line-height: 48px;
        }
        .product_listing {
          margin: 0px auto !important;
          max-width: 1055px;

          .product_element {
            > a {
              padding: 44px 0px 35px 0px;
              width: 343px;
            }
            .title {

              h3 {
                margin-bottom: 0px;
                font-size: 22px;
                line-height: 28px;
              }
              p {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
        .owl-carousel {
          position: initial;

          .owl-stage-outer {
            overflow: hidden;
            padding: 40px 0px;
          }
          .owl-nav {
            top: 50%;
          }
        }
      }
    }
  }
}

.editor_type_texts_banner {
  padding: 0px;

  .inner-wrp {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;

    .banner-wrp {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      position: relative;
      overflow: hidden;
      margin: -40px 27px 0px 27px;
      min-height: 290px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: initial;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
    .text-wrp {
      &.text-top-wrp {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: 45px 28px 75px 28px;
        background-color: #F9F9FB;

        h1 {
          margin-top: 0px;
          margin-bottom: 9px;
        }
        p {
          line-height: 20px;
        }
      }
      &.text-bottom-wrp {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        padding: 54px 28px 32px 28px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto;

      .banner-wrp {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        margin: 0px;

        img {
          width: auto;
        }
      }
      .text-wrp {
        &.text-top-wrp {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          padding: 96px 69px 79px 69px;

          .inner-text-wrp {
            max-width: 380px;
          }
          h1 {
            margin-bottom: 19px;
          }
          p {
            line-height: 26px;
          }
        }
        &.text-bottom-wrp {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          padding: 101px 69px 75px 69px;

          .inner-text-wrp {
            max-width: 500px;
          }
        }
      }
    }
  }
}

.editor_type_banners_slider  {
  padding: 0px;
}