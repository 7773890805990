@import "theme-variables";
@import "vendors";

$color-bg: #FCD8C7;
$color-text: #843B03;
$color-secondary: #EFC9B8;
$color-attention: #CF0000;

@keyframes working {
  15% {
    -webkit-transform: rotate(0);
    transform:rotate(0);
    transform-origin: bottom right;
  }
  30% {
    -webkit-transform: rotate(-25deg);
    transform:rotate(-25deg);
    transform-origin: bottom right;
  }
  35% {
    -webkit-transform: rotate(-23deg);
    transform:rotate(-23deg);
    transform-origin: bottom right;
  }
  38% {
    -webkit-transform: rotate(-25deg);
    transform:rotate(-25deg);
    transform-origin: bottom right;
  }
  80% {
    -webkit-transform: rotate(-25deg);
    transform:rotate(-25deg);
    transform-origin: bottom right;
  }
  100% {
    -webkit-transform: rotate(0);
    transform:rotate(0);
    transform-origin: bottom right;
  }
}

@keyframes show-more {
  15% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.2) translateY(12px);
    transform: scale(1.2) translateY(12px);
  }
  35% {
    -webkit-transform: scale(1.2) translateY(10px);
    transform: scale(1.2) translateY(10px);
  }
  38% {
    -webkit-transform: scale(1.2) translateY(12px);
    transform: scale(1.2) translateY(12px);
  }
  80% {
    -webkit-transform: scale(1.2) translateY(12px);
    transform: scale(1.2) translateY(12px);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

body {
  background-color: $color-bg;
  color: $color-text;
  font-family: 'Jost', 'sans-serif';
  font-size: 16px;
  @media (max-width: 991px) {
    font-size: 18px;
  }
}

button {
  background: none;
  border: none;
  color: $color-text;
  &:focus {
    outline: none;
  }
  &::selection {
    background: #000;
    color: #FFF;
  }
}

h2 {
  font-family: 'Georgia', 'sans-serif';
  font-size: 32px;
  margin-bottom: 35px;
  margin-top: 0;
  line-height: 36px;
  &::selection {
    background: #000;
    color: #FFF;
  }
  @media (max-width: 991px) {
    margin-top: 125px;
  }
}

h3 {
  font-family: 'Jost', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 991px) {
    font-size: 18px;
  }
  &::selection {
    background: #000;
    color: #FFF;
  }
}

.divider {
  border-top: 2px solid $color-text;
  display: block;
  margin-bottom: 105px;
  width: 57px;
  -webkit-transform: translateX(-200px);
  transform: translateX(-200px);
  transition: transform 1.8s ease;
  @media (max-width: 991px) {
    margin-bottom: 50px;
    transform: none;
  }
}

a {
  color: $color-text;
  &:hover {
    color: $color-text;
    text-decoration: none;
  }
  &::selection {
    background: #000;
    color: #FFF;
  }
}

p {
  &::selection {
    background: #000;
    color: #FFF;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  margin-top: 50px;
  width: 100%;
  z-index: 2;
  @media (max-width: 991px) {
    display: none;
  }
}

.mobile-header {
  background-color: $color-bg;
  box-shadow: 0 30px 10px -8px rgba(247, 200, 178, 0.3);
  padding-bottom: 15px;
  padding-top: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 991px) {
    img {
      max-width: 215px;
    }
  }
  .burger {
    padding: 10px;
    position: absolute;
    left: 15px;
    top: 25px;
    span {
      border-top: 2px solid $color-text;
      display: block;
      transition: transform 0.8s ease-in-out;
      width: 20px;
      &:not(:first-of-type) {
        margin-top: 6px;
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 90px;
  width: 100%;
  z-index: 2;
  -webkit-transform: translateY(-400px);
  transform: translateY(-400px);
  transition: transform 0.8s ease-in-out;
  @media (min-width: 992px) {
    display: none;
  }
  .menu-link {
    background-color: $color-bg;
    border-bottom: 1px solid $color-secondary;
    display: block;
    font-size: 22px;
    padding: 15px 25px;
    text-align: left;
    width: 100%;
    &:hover {
      background-color: $color-secondary;
    }
  }
}

body.open-menu {
  .mobile-header {
    .burger {
      span {
        &:first-of-type {
          -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
        }
        &:last-of-type {
          -webkit-transform: translateY(3px);
          transform: translateY(3px);
        }
      }
    }
  }
  .mobile-menu {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.navigation-block {
  position: fixed;
  bottom: 15vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: bottom 1.8s ease;
  @media (min-width: 992px) and (max-height: 900px) {
    bottom: 5vh;
  }
  @media (max-width: 991px) {
    bottom: 5vh;
    z-index: 3;
  }
  .show-more {
    transition: transform .3s ease;
    @media (max-width: 991px) {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
    &:hover {
      -webkit-transform: scale(1.2) translateY(12px);
      transform: scale(1.2) translateY(12px);
      @media (max-width: 991px) {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
      }
    }
  }
  .prev-page {
    display: block;
    margin: 0 auto 80px;
    opacity: 0;
    transition: all 1.5s ease;
    .show-more {
      &:hover {
        -webkit-transform: scale(1.2) translateY(-12px);
        transform: scale(1.2) translateY(-12px);
      }
    }
  }
  p {
    opacity: 0;
    margin-top: 25px;
    transition: all .5s ease;
    @media (max-width: 991px) {
      opacity: 1;
    }
  }
}

.fp-section {
  opacity: 0;
  transition: all 1.8s ease;
  &.active {
    opacity: 1;
  }
}

.fp-tableCell {
  vertical-align: top;
}

.fp-viewing-0 {
  .navigation-block {
    p {
      opacity: 1;
    }
    .show-more {
      @media (min-width: 992px) {
        animation: show-more 1.2s linear infinite;
      }
    }
  }
  .working-block {
    .hammer {
      animation: working 1.2s linear infinite;
    }
  }
}

[class^='fp-viewing-1'] {
  .navigation-block {
    bottom: 5vh;
  }
}

.fp-viewing-2 {
  .about-page {
    .divider {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    .info-section {
      .info-bg {
        max-height: 915px;
        max-width: 819px;
        width: calc((100vh - 245px) * 819 / 915);
      }
    }
    .menu-about {
      font-weight: 600;
    }
  }
  .navigation-block {
    bottom: -40px;
    .prev-page {
      opacity: 1;
      -webkit-transform: translateY(calc(-100vh + 350px));
      transform: translateY(calc(-100vh + 350px));
    }
    .prev-page {
      opacity: 1;
    }
  }
}

.fp-viewing-3 {
  .brands-page {
    .divider {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    .menu-brands {
      font-weight: 600;
    }
  }
  .navigation-block {
    bottom: -40px;
    .prev-page {
      -webkit-transform: translateY(calc(-100vh + 350px));
      transform: translateY(calc(-100vh + 350px));
    }
    button {
      opacity: 0;
      &.show {
        opacity: 1;
      }
    }
  }
  #brands_listing {
    .row {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}

.fp-viewing-4 {
  .contacts-page {
    .divider {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    .info-section {
      .info-bg {
        max-height: 876px;
        max-width: 664px;
        width: calc((100vh - 245px) * 664 / 876);
      }
    }
    .menu-contacts {
      font-weight: 600;
    }
  }
  .navigation-block {
    bottom: -40px;
    .prev-page {
      opacity: 1;
      -webkit-transform: translateY(calc(-100vh + 350px));
      transform: translateY(calc(-100vh + 350px));
    }
    .prev-page {
      opacity: 1;
    }
  }
}

.fp-viewing-5 {
  .subscribe-page {
    .divider {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    .menu-subscribe {
      font-weight: 600;
    }
  }
  .navigation-block {
    bottom: -150px;
    .prev-page {
      opacity: 1;
    }
    .next-page {
      opacity: 0;
    }
  }
}

#intro-page {
  height: 100vh;
  padding-top: 30vh;
  position: relative;
  text-align: center;
  @media (max-width: 991px) {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20vh;
  }
  a {
    color: $color-text;
  }
  .working-block {
    font-size: 26px;
    margin: 55px auto 0;
    @media (max-width: 991px) {
      font-size: 22px;
      margin-top: 90px;
      .hammer {
        animation: working 1.2s linear infinite;
      }
    }
    @media (max-height: 650px) {
      margin-top: 30px;
    }
    p {
      margin-top: 25px;
      @media (max-width: 991px) {
        margin-top: 55px;
      }
    }
    .working {
      margin: 0 auto;
      padding-left: 5px;
      position: relative;
      width: 55px;
    }
    .nail {
      position: absolute;
      left: -4px;
      bottom: 0;
    }
  }
  @media (max-width: 991px) {
    #logo {
      img {
        max-width: 100%;
      }
    }
  }
}

.hero-page {
  position: relative;
  @media (max-width: 991px) {
    padding-top: 90px;
  }
  .header {
    position: absolute;
    transform-style: preserve-3d;
    top: 0;
    &::before {
      content: '';
      background-image: linear-gradient($color-bg, transparent);
      position: absolute;
      top: -50px;
      display: block;
      width: 100%;
      height: 500px;
      transform: translateZ(-1px);
    }
  }
  .slide {
    background-size: cover;
    background-position: center top;
    @media (max-width: 991px) {
      height: calc(100vh - 90px);
      width: 100%;
    }
  }
  .fp-controlArrow {
    border: none;
    height: 61px;
    width: 32px;
    &.fp-prev {
      background-image: url('../../../images/frontend/arrow-left.png');
      left: 7vw;
    }
    &.fp-next {
      background-image: url('../../../images/frontend/arrow-right.png');
      right: 7vw;
    }
  }
}

.info-section {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media (max-width: 991px) {
    position: static;
  }
  .info-bg {
    background-size: cover;
    height: calc(100vh - 245px);
    margin-left: auto;
    transition: width 1.8s ease;
    width: 0;
  }
  .info-bg-box {
    display: inline-block;
    height: calc(100vh - 245px);
    vertical-align: top;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .info {
    display: inline-block;
    vertical-align: top;
    margin-left: 55px;
    max-width: 430px;
    @media (max-width: 991px) {
      margin-left: 45px;
      margin-right: 45px;
      p {
        margin-bottom: 24px;
      }
    }
    a {
      display: block;
      img {
        margin-right: 10px;
        vertical-align: sub;
      }
    }
    .next-page {
      display: none;
      @media (max-width: 991px) {
        display: block;
        margin: 100px auto 0;
      }
    }
  }
}

.contacts-page {
  @media (max-width: 991px) {
    h2 {
      margin-top: 40px;
    }
    p {
      &:last-of-type {
        font-size: 16px;
      }
    }
  }
  h3 {
    margin-bottom: 3px;
    &:nth-of-type(2) {
      margin-top: 90px;
      @media (max-width: 991px) {
        margin-top: 35px;
      }
    }
    @media (max-width: 991px) {
      &:last-of-type {
        font-size: 16px;
      }
    }
  }
  .contacts-block {
    margin-top: 25px;
    @media (max-width: 991px) {
      &:last-of-type {
        font-size: 16px;
        margin-top: 30px;
        a {
          display: inline-block;
          margin-right: 30px;
        }
      }
    }
  }
  .divider {
    margin-bottom: 90px;
    @media (max-width: 991px) {
      margin-bottom: 35px;
    }
  }
  .mobile-image {
    display: none;
    @media (max-width: 991px) {
      display: block;
      img {
        max-width: 100%;
      }
    }
  }
}

#brands_listing {
  padding: 0 45px 75px;
  @media (max-width: 991px) {
    margin-bottom: 50px;
    h2 {
      padding-top: 140px;
      margin-top: 0;
    }
  }
  .row {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    transition: transform 1.8s ease;
    @media (max-width: 991px) {
      -webkit-transform: none;
      transform: none;
    }
  }
  .divider {
    margin-bottom: 25px;
  }
  .img-fluid {
    margin-bottom: 20px;
    margin-top: 15px;
    max-height: 40px;
    max-width: 260px;
    @media (max-width: 991px) {
      cursor: pointer;
      max-width: 200px;
    }
  }
  .show-more {
    cursor: pointer;
    width: 20px;
    margin-left: 10px;
    vertical-align: baseline;
    @media (min-width: 992px) {
      display: none;
    }
  }
  .text {
    max-width: 450px;
    @media (max-width: 991px) {
      height: 0;
      opacity: 0;
    }
  }
  .col {
    @media (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 20px;
      &.open {
        .show-more {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          transition: all 0.5s ease;
        }
        .text {
          height: 100%;
          opacity: 1;
          transition: all 0.5s ease;
        }
      }
    }
  }
}

.subscribe-box {
  margin: 85px auto 0;
  max-width: 430px;
  @media (max-width: 991px) {
    padding-bottom: 350px;
    margin-left: 45px;
    margin-right: 45px;
    h2 {
      margin-top: 145px;
    }
  }
  .alert-danger, .alert-info {
    background: none;
    border: none;
    color: $color-attention;
    margin-top: 20px;
    padding-left: 0;
    .close {
      display: none;
    }
    &:before {
      content:url('../../../images/frontend/error.png');
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  .alert-success {
    background-color: #FFF;
    border: none;
    color: $color-text;
    padding: 28px 27px 38px;
    position: absolute;
    top: -60px;
    opacity: 0;
    transition: all 1s ease;
    @media (max-width: 991px) {
      top: -100px;
    }
    &::after {
      content: '';
      background-color: $color-bg;
      height: 50%;
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 1s ease;
      transform-origin: top;
    }
    &::before {
      content: '';
      background-color: $color-bg;
      height: 50%;
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: transform 1s ease;
      transform-origin: bottom;
    }
    &.open {
      opacity: 1;
      &::after {
        transform: scaleY(0);
      }
      &::before {
        transform: scaleY(0);
      }
    }
    a {
      margin-right: 50px;
      @media (max-width: 991px) {
        display: block;
        margin-right: 0;
      }
      img {
        margin-right: 8px;
        vertical-align: top;
      }
    }
    h3 {
      margin-bottom: 4px;
    }
    p {
      margin-bottom: 23px;
    }
    .close {
      display: none;
    }
  }
  .btn {
    background-color: $color-secondary;
    border-radius: 2px;
    color: $color-text;
    font-size: 16px;
    margin-top: 40px;
    padding: 15px 45px;
    transition: all .3s ease;
    width: 185px;
    &:hover {
      background-color: #F6D0BF;
      letter-spacing: 0.5px;
    }
  }
  .divider {
    margin-bottom: 145px;
    @media (max-width: 991px) {
      margin-bottom: 55px;
    }
  }
  .email-input {
    border: none;
    color: $color-text;
    font-size: 16px;
    padding: 25px 17px;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: $color-secondary;
      font-size: 16px;
    }
  }
  #newsletter_form {
    margin-top: 30px;
  }
  #subscribers_subscribe {
    position: relative;
  }
}

@media (max-width: 991px) {
  html {
    &:not(.open) {
      height: 100%;
      overflow-y: hidden;
      body {
        height: 100%;
        overflow-y: hidden;
      }
    }
    &.open {
      .intro-page {
        display: none;
      }
      .mobile-header {
        z-index: 4;
      }
      .navigation-block {
        display: none;
      }
    }
  }
  .owl-carousel {
    .owl-dots {
      bottom: 30px;
      .owl-dot {
        span {
          height: 12px;
          width: 12px;
          margin-left: 5px;
          margin-right: 5px;
        }
        &.active {
          span {
            background-color: $color-bg;
          }
        }
        &:hover {
          span {
            background-color: $color-bg;
          }
        }
      }
    }
  }
  .intro-page {
    background-color: $color-bg;
    position: fixed;
    top: 0;
    transition: opacity .3s ease;
    z-index: 3;
  }
}