@charset "UTF-8";
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #d3408f;
  --secondary: #6c757d;
  --success: #5cb85c;
  --info: #5bc0de;
  --warning: #f0ad4e;
  --danger: #fa0f3d;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857143;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #d3408f;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #a12568;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f3cae0;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #e89cc5;
}

.table-hover .table-primary:hover {
  background-color: #eeb5d4;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #eeb5d4;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d1ebd1;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #aadaaa;
}

.table-hover .table-success:hover {
  background-color: #bfe3bf;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bfe3bf;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d1edf6;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #aadeee;
}

.table-hover .table-info:hover {
  background-color: #bce5f2;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #bce5f2;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbe8cd;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7d4a3;
}

.table-hover .table-warning:hover {
  background-color: #f9ddb5;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9ddb5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #febcc9;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fc829a;
}

.table-hover .table-danger:hover {
  background-color: #fea3b5;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fea3b5;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.42857143em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857143;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #eba8cc;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.42857143;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.42857143;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5cb85c;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  line-height: 1.42857143;
  color: #fff;
  background-color: rgba(92, 184, 92, 0.9);
  border-radius: 0;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #5cb85c;
  padding-right: calc(1.42857143em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3571428575em + 0.1875rem) center;
  background-size: calc(0.714285715em + 0.375rem) calc(0.714285715em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #5cb85c;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.42857143em + 0.75rem);
  background-position: top calc(0.3571428575em + 0.1875rem) right calc(0.3571428575em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5cb85c;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.714285715em + 0.375rem) calc(0.714285715em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #5cb85c;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5cb85c;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5cb85c;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #5cb85c;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #80c780;
  background-color: #80c780;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5cb85c;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5cb85c;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5cb85c;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fa0f3d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  line-height: 1.42857143;
  color: #fff;
  background-color: rgba(250, 15, 61, 0.9);
  border-radius: 0;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #fa0f3d;
  padding-right: calc(1.42857143em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fa0f3d' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa0f3d' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3571428575em + 0.1875rem) center;
  background-size: calc(0.714285715em + 0.375rem) calc(0.714285715em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #fa0f3d;
  box-shadow: 0 0 0 0.2rem rgba(250, 15, 61, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.42857143em + 0.75rem);
  background-position: top calc(0.3571428575em + 0.1875rem) right calc(0.3571428575em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #fa0f3d;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fa0f3d' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa0f3d' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.714285715em + 0.375rem) calc(0.714285715em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #fa0f3d;
  box-shadow: 0 0 0 0.2rem rgba(250, 15, 61, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fa0f3d;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #fa0f3d;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #fa0f3d;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fb4165;
  background-color: #fb4165;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(250, 15, 61, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fa0f3d;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #fa0f3d;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #fa0f3d;
  box-shadow: 0 0 0 0.2rem rgba(250, 15, 61, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.42857143;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #d3408f;
  border-color: #d3408f;
}
.btn-primary:hover {
  color: #fff;
  background-color: #c02c7c;
  border-color: #b62a75;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #c02c7c;
  border-color: #b62a75;
  box-shadow: 0 0 0 0.2rem rgba(218, 93, 160, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #d3408f;
  border-color: #d3408f;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #b62a75;
  border-color: #ac286f;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 93, 160, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-success:hover {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
  box-shadow: 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #409440;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-info:hover {
  color: #fff;
  background-color: #3bb4d8;
  border-color: #31b0d5;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #3bb4d8;
  border-color: #31b0d5;
  box-shadow: 0 0 0 0.2rem rgba(82, 169, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aaacf;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 169, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-warning:hover {
  color: #212529;
  background-color: #ed9d2b;
  border-color: #ec971f;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #ed9d2b;
  border-color: #ec971f;
  box-shadow: 0 0 0 0.2rem rgba(209, 153, 72, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ec971f;
  border-color: #ea9214;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 153, 72, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #fa0f3d;
  border-color: #fa0f3d;
}
.btn-danger:hover {
  color: #fff;
  background-color: #de052f;
  border-color: #d2042d;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #de052f;
  border-color: #d2042d;
  box-shadow: 0 0 0 0.2rem rgba(251, 51, 90, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #fa0f3d;
  border-color: #fa0f3d;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d2042d;
  border-color: #c5042a;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 51, 90, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #d3408f;
  border-color: #d3408f;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #d3408f;
  border-color: #d3408f;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #d3408f;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #d3408f;
  border-color: #d3408f;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #5cb85c;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-outline-info {
  color: #5bc0de;
  border-color: #5bc0de;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #5bc0de;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.btn-outline-warning {
  color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f0ad4e;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5);
}

.btn-outline-danger {
  color: #fa0f3d;
  border-color: #fa0f3d;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fa0f3d;
  border-color: #fa0f3d;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 15, 61, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #fa0f3d;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fa0f3d;
  border-color: #fa0f3d;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 15, 61, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #d3408f;
  text-decoration: none;
}
.btn-link:hover {
  color: #a12568;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #d3408f;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857143;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.2500000012rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.1250000006rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #d3408f;
  background-color: #d3408f;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #eba8cc;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f4d1e4;
  border-color: #f4d1e4;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.1250000006rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.1250000006rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #d3408f;
  background-color: #d3408f;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(211, 64, 143, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(211, 64, 143, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(211, 64, 143, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.1250000006rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(211, 64, 143, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.42857143em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857143;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none;
}
.custom-select:focus {
  border-color: #eba8cc;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.42857143em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.42857143em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #eba8cc;
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.42857143em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.42857143;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.42857143em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.42857143;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #d3408f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #f4d1e4;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #d3408f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #f4d1e4;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #d3408f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #f4d1e4;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #d3408f;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3437499998rem;
  padding-bottom: 0.3437499998rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #d3408f;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #a12568;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #d3408f;
  border-color: #d3408f;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #d3408f;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #b62a75;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 64, 143, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #5cb85c;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #449d44;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #5bc0de;
}
a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #31b0d5;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #f0ad4e;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ec971f;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #fa0f3d;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #d2042d;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 15, 61, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #6e214a;
  background-color: #f6d9e9;
  border-color: #f3cae0;
}
.alert-primary hr {
  border-top-color: #eeb5d4;
}
.alert-primary .alert-link {
  color: #471530;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #306030;
  background-color: #def1de;
  border-color: #d1ebd1;
}
.alert-success hr {
  border-top-color: #bfe3bf;
}
.alert-success .alert-link {
  color: #1f3e1f;
}

.alert-info {
  color: #2f6473;
  background-color: #def2f8;
  border-color: #d1edf6;
}
.alert-info hr {
  border-top-color: #bce5f2;
}
.alert-info .alert-link {
  color: #20454f;
}

.alert-warning {
  color: #7d5a29;
  background-color: #fcefdc;
  border-color: #fbe8cd;
}
.alert-warning hr {
  border-top-color: #f9ddb5;
}
.alert-warning .alert-link {
  color: #573e1c;
}

.alert-danger {
  color: #820820;
  background-color: #fecfd8;
  border-color: #febcc9;
}
.alert-danger hr {
  border-top-color: #fea3b5;
}
.alert-danger .alert-link {
  color: #520514;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #d3408f;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #d3408f;
  border-color: #d3408f;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #6e214a;
  background-color: #f3cae0;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #6e214a;
  background-color: #eeb5d4;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #6e214a;
  border-color: #6e214a;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #306030;
  background-color: #d1ebd1;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #306030;
  background-color: #bfe3bf;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #306030;
  border-color: #306030;
}

.list-group-item-info {
  color: #2f6473;
  background-color: #d1edf6;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #2f6473;
  background-color: #bce5f2;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #2f6473;
  border-color: #2f6473;
}

.list-group-item-warning {
  color: #7d5a29;
  background-color: #fbe8cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7d5a29;
  background-color: #f9ddb5;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7d5a29;
  border-color: #7d5a29;
}

.list-group-item-danger {
  color: #820820;
  background-color: #febcc9;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #820820;
  background-color: #fea3b5;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #820820;
  border-color: #820820;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #d3408f !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #b62a75 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #5cb85c !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #449d44 !important;
}

.bg-info {
  background-color: #5bc0de !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #31b0d5 !important;
}

.bg-warning {
  background-color: #f0ad4e !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ec971f !important;
}

.bg-danger {
  background-color: #fa0f3d !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d2042d !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #d3408f !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #5cb85c !important;
}

.border-info {
  border-color: #5bc0de !important;
}

.border-warning {
  border-color: #f0ad4e !important;
}

.border-danger {
  border-color: #fa0f3d !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #d3408f !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #a12568 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #5cb85c !important;
}

a.text-success:hover, a.text-success:focus {
  color: #3d8b3d !important;
}

.text-info {
  color: #5bc0de !important;
}

a.text-info:hover, a.text-info:focus {
  color: #28a1c5 !important;
}

.text-warning {
  color: #f0ad4e !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #df8a13 !important;
}

.text-danger {
  color: #fa0f3d !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b90427 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*
 * Z-indexes
 */
.owl-carousel {
  position: relative;
}
.owl-carousel .owl-item > div {
  width: 100%;
}
.owl-carousel .owl-nav {
  margin: 0 !important;
  display: block;
}
.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
  background: #dee2e6;
  transition: background-color 0.5s, color 0.5s;
  color: #000;
  position: initial;
  top: 50%;
  margin-top: -25px;
  border-radius: 0;
  font-size: 30px;
}
.owl-carousel .owl-nav .owl-prev.disabled, .owl-carousel .owl-nav .owl-next.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev:hover, .owl-carousel .owl-nav .owl-next:hover {
  background: #d3408f;
  color: #fff;
}
.owl-carousel .owl-nav .owl-prev {
  left: 0;
  float: left;
}
.owl-carousel .owl-nav .owl-next {
  right: 0;
  float: right;
}
.owl-carousel .owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}
.owl-carousel .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #dee2e6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
  background: #d3408f;
}

.owl-out-nav, .owl-nav {
  position: absolute;
  left: 0px;
  top: calc(50% - 15px);
  width: 100%;
}
.owl-out-nav .owl-prev, .owl-out-nav .owl-next, .owl-nav .owl-prev, .owl-nav .owl-next {
  position: absolute;
  background: none;
  border: none;
}
.owl-out-nav .owl-prev .icon, .owl-out-nav .owl-next .icon, .owl-nav .owl-prev .icon, .owl-nav .owl-next .icon {
  font-size: 22px;
}
.owl-out-nav .owl-prev:hover, .owl-out-nav .owl-next:hover, .owl-nav .owl-prev:hover, .owl-nav .owl-next:hover {
  background: none !important;
  color: #000 !important;
}
.owl-out-nav .owl-prev.disabled, .owl-out-nav .owl-next.disabled, .owl-nav .owl-prev.disabled, .owl-nav .owl-next.disabled {
  display: none;
}
.owl-out-nav .owl-prev, .owl-nav .owl-prev {
  left: -10px;
}
.owl-out-nav .owl-next, .owl-nav .owl-next {
  right: -10px;
}

.owl-nav:not(.owl-out-nav) .owl-prev {
  left: -35px;
}
.owl-nav:not(.owl-out-nav) .owl-next {
  right: -35px;
}

.owl-container {
  padding: 0;
}
.owl-container .owl-carousel {
  margin: 0 !important;
  padding: 10px;
}

table.loadtime_info {
  border-collapse: collapse;
  margin: 1em;
}
table.loadtime_info td, table.loadtime_info th {
  text-align: left;
  font-size: 12px;
  padding: 4px;
  border: 1px solid #ced4da;
}
table.loadtime_info th {
  background: #e9ecef;
}
table.loadtime_info tr.timeclass100 {
  background: #ffc;
}
table.loadtime_info tr.timeclass200 {
  background: #ffff7a;
}
table.loadtime_info tr.timeclass500 {
  background: #ffc145;
}
table.loadtime_info tr.timeclass1000 {
  background: #ff6c6c;
}

.linepromos {
  z-index: 601;
}
.linepromos .item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px 15px !important;
  padding: 0.3125rem;
  text-align: center;
}
.linepromos .item p {
  margin-bottom: 0px;
}
@media screen and (min-width: 768px) {
  .linepromos .item {
    padding: 8px 15px !important;
    padding: 0.5rem;
  }
}
.linepromos .owl-carousel .owl-stage {
  display: flex;
  flex-direction: row;
}
.linepromos .owl-carousel .owl-stage:after {
  display: none;
}
.linepromos .owl-carousel .owl-stage .eowl-item {
  float: none;
}
.linepromos .owl-carousel .owl-nav button.owl-prev, .linepromos .owl-carousel .owl-nav button.owl-next {
  margin-top: 0px;
  padding: 5px !important;
  background: none;
  color: #fff;
}
.linepromos .owl-carousel .owl-nav button.owl-prev:hover, .linepromos .owl-carousel .owl-nav button.owl-next:hover {
  background: none !important;
}
@media (max-width: 768px) {
  .linepromos {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
  }
}

* {
  margin: 0;
  padding: 0;
}

.btn-outline-custom {
  color: #ced4da;
  border-color: #ced4da;
  color: #495057 !important;
}
.btn-outline-custom:hover {
  color: #212529;
  background-color: #dde1e5;
  border-color: #ced4da;
}
.btn-outline-custom:focus, .btn-outline-custom.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-outline-custom.disabled, .btn-outline-custom:disabled {
  color: #ced4da;
  background-color: transparent;
}
.btn-outline-custom:not(:disabled):not(.disabled):active, .btn-outline-custom:not(:disabled):not(.disabled).active, .show > .btn-outline-custom.dropdown-toggle {
  color: #212529;
  background-color: #dde1e5;
  border-color: #ced4da;
}
.btn-outline-custom:not(:disabled):not(.disabled):active:focus, .btn-outline-custom:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-custom.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0.5em 0;
}

h1, .h1, h2, .h2 {
  font-family: "Calibre", sans-serif;
}
h1 .btn, .h1 .btn, h2 .btn, .h2 .btn {
  font-family: "Calibre", sans-serif;
}

h1, .h1 {
  font-weight: 100;
}

h2, .h2 {
  font-weight: 300;
}

h3, .h3 {
  font-weight: normal;
}

h4, .h4 {
  font-weight: 500;
}

h5, .h5, h6, .h6 {
  font-weight: bold;
}

a {
  cursor: pointer;
}
a:focus {
  outline: none;
}

.link, .link-default {
  cursor: pointer;
}
.link-primary {
  cursor: pointer;
  color: #d3408f;
}
.link-primary:hover {
  color: #d3408f;
  text-decoration: underline;
}

.link-quiet {
  cursor: pointer;
  color: #6c757d;
  text-decoration: none;
}
.link-quiet:hover {
  color: #343a40;
}

.thin {
  font-weight: 100;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: normal;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.extrabold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

@media (max-width: 576px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h1, h2 {
    margin: 1em 0;
  }
}
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 1300px;
  width: 100%;
}

.content_layout::after {
  display: block;
  clear: both;
  content: "";
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

button::-moz-focus-inner {
  border: 0;
}

ul.menu-horizontal, ul.horizontal {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.menu-horizontal::after, ul.horizontal::after {
  display: block;
  clear: both;
  content: "";
}
ul.menu-horizontal > li, ul.horizontal > li {
  float: left;
  white-space: nowrap;
  margin-left: 20px;
  line-height: 2em;
}
ul.menu-horizontal > li:first-child, ul.horizontal > li:first-child {
  margin-left: 0;
}
ul.menu-horizontal > li > a, ul.horizontal > li > a {
  color: #495057;
  text-decoration: none;
  display: block;
}
ul.menu-horizontal > li > a:hover, ul.horizontal > li > a:hover {
  color: #212529;
}

@media (min-width: 992px) {
  ul.menu-horizontal-desktop,
ul.horizontal-desktop {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ul.menu-horizontal-desktop::after,
ul.horizontal-desktop::after {
    display: block;
    clear: both;
    content: "";
  }
  ul.menu-horizontal-desktop > li,
ul.horizontal-desktop > li {
    float: left;
    white-space: nowrap;
    margin-left: 20px;
    line-height: 2em;
  }
  ul.menu-horizontal-desktop > li:first-child,
ul.horizontal-desktop > li:first-child {
    margin-left: 0;
  }
  ul.menu-horizontal-desktop > li > a,
ul.horizontal-desktop > li > a {
    color: #495057;
    text-decoration: none;
    display: block;
  }
  ul.menu-horizontal-desktop > li > a:hover,
ul.horizontal-desktop > li > a:hover {
    color: #212529;
  }
}
@media (max-width: 992px) {
  ul.menu-horizontal-mobile,
ul.horizontal-mobile {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ul.menu-horizontal-mobile::after,
ul.horizontal-mobile::after {
    display: block;
    clear: both;
    content: "";
  }
  ul.menu-horizontal-mobile > li,
ul.horizontal-mobile > li {
    float: left;
    white-space: nowrap;
    margin-left: 20px;
    line-height: 2em;
  }
  ul.menu-horizontal-mobile > li:first-child,
ul.horizontal-mobile > li:first-child {
    margin-left: 0;
  }
  ul.menu-horizontal-mobile > li > a,
ul.horizontal-mobile > li > a {
    color: #495057;
    text-decoration: none;
    display: block;
  }
  ul.menu-horizontal-mobile > li > a:hover,
ul.horizontal-mobile > li > a:hover {
    color: #212529;
  }

  ul.menu-horizontal-desktop,
ul.horizontal-desktop {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul.menu-horizontal-desktop > li > a,
ul.horizontal-desktop > li > a {
    line-height: 20px;
    margin: 0;
    display: block;
    text-decoration: none;
    color: #495057;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
  }
  ul.menu-horizontal-desktop > li > a:hover,
ul.horizontal-desktop > li > a:hover {
    background: #f8f9fa;
  }
  ul.menu-horizontal-desktop > li > a > h2, ul.menu-horizontal-desktop > li > a > h3, ul.menu-horizontal-desktop > li > a > h4, ul.menu-horizontal-desktop > li > a > h5,
ul.horizontal-desktop > li > a > h2,
ul.horizontal-desktop > li > a > h3,
ul.horizontal-desktop > li > a > h4,
ul.horizontal-desktop > li > a > h5 {
    margin: 0;
  }
  ul.menu-horizontal-desktop > li > a > .check,
ul.horizontal-desktop > li > a > .check {
    margin-top: 2px;
  }
}
@media (max-width: 992px) and (max-width: 992px) {
  ul.menu-horizontal-desktop > li > a,
ul.horizontal-desktop > li > a {
    background-color: #fff;
  }
  ul.menu-horizontal-desktop > li > a:hover,
ul.horizontal-desktop > li > a:hover {
    background-color: #fff;
  }
}
.list-default ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-default ul .title, .list-default ul a {
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
}
.list-default ul li {
  position: relative;
  padding-left: 15px;
}
.list-default ul li.active, .list-default ul li.active > a {
  font-weight: bold;
}
.list-default ul li:before {
  position: absolute;
  left: 0;
  top: 0;
  padding-right: 5px;
  line-height: inherit;
  margin: 0;
}
.list-default ul li:not(.icon):before {
  content: "–";
}
.list-checkboxes > ul, .list-checkboxes ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-checkboxes > ul > .title, .list-checkboxes ul > .title {
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
}
.list-checkboxes > ul > li, .list-checkboxes ul > li {
  position: relative;
  padding-left: 20px;
  margin: 0.5em 0;
  line-height: 1.2em;
}
.list-checkboxes > ul > li > span, .list-checkboxes ul > li > span {
  cursor: pointer;
}
.list-checkboxes > ul > li.count0, .list-checkboxes ul > li.count0 {
  color: #ced4da !important;
}
.list-checkboxes > ul > li > span.check, .list-checkboxes ul > li > span.check {
  position: absolute;
  width: 1em;
  height: 1em;
  margin-left: -20px;
  border: 1px solid #000;
}
.list-checkboxes > ul > li.active > span.check, .list-checkboxes ul > li.active > span.check {
  background: #000;
  color: #fff;
}
.list-checkboxes > ul > li.active > span.check:before, .list-checkboxes ul > li.active > span.check:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  content: "\e902";
  margin: 0;
  vertical-align: top;
  line-height: 1em;
}
.list-checkboxes > ul > li .count, .list-checkboxes ul > li .count {
  margin-left: 5px;
  font-size: 10px;
  color: #6c757d;
}
@media (max-width: 992px) {
  .list-checkboxes.list-collapse-mobile > ul > li {
    line-height: 20px;
    margin: 0;
    display: block;
    text-decoration: none;
    color: #495057;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
    padding-left: 30px;
  }
  .list-checkboxes.list-collapse-mobile > ul > li:hover {
    background: #f8f9fa;
  }
  .list-checkboxes.list-collapse-mobile > ul > li > h2, .list-checkboxes.list-collapse-mobile > ul > li > h3, .list-checkboxes.list-collapse-mobile > ul > li > h4, .list-checkboxes.list-collapse-mobile > ul > li > h5 {
    margin: 0;
  }
  .list-checkboxes.list-collapse-mobile > ul > li > .check {
    margin-top: 2px;
  }
}
@media (max-width: 992px) and (max-width: 992px) {
  .list-checkboxes.list-collapse-mobile > ul > li {
    background-color: #fff;
  }
  .list-checkboxes.list-collapse-mobile > ul > li:hover {
    background-color: #fff;
  }
}
@media (max-width: 992px) {
  .list-checkboxes.list-collapse-mobile > ul > li:before {
    left: 10px;
  }
}

.list-treeul, .list-tree ul {
  list-style-type: none;
  padding: 0;
}
.list-treeul ul, .list-tree ul ul {
  margin-left: 20px;
}
.list-tree li.parent > span {
  cursor: pointer;
}
.list-tree li.parent > span.arr:after {
  content: "▸";
  display: inline-block;
  margin-left: 5px;
  font-style: normal;
}
.list-tree li.parent > ul {
  display: none;
}
.list-tree li.parent.expanded > span.arr:after {
  content: "▾";
}
.list-tree li.parent.expanded > ul {
  display: block;
}

@media (max-width: 992px) {
  .list-collapse-mobile.list-tree > ul {
    margin: 10px;
  }
}

.list-tree-checkboxesul ul, .list-tree-checkboxes ul ul {
  margin-left: 0;
}
.list-tree-checkboxes li {
  position: relative;
  padding-left: 20px;
  margin: 0.5em 0;
  line-height: 1.2em;
}
.list-tree-checkboxes li > span {
  cursor: pointer;
}
.list-tree-checkboxes li.count0 {
  color: #ced4da !important;
}
.list-tree-checkboxes li > span.check {
  position: absolute;
  width: 1em;
  height: 1em;
  margin-left: -20px;
  border: 1px solid #000;
}
.list-tree-checkboxes li.active > span.check {
  background: #000;
  color: #fff;
}
.list-tree-checkboxes li.active > span.check:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  content: "\e902";
  margin: 0;
  vertical-align: top;
  line-height: 1em;
}
.list-tree-checkboxes li .count {
  margin-left: 5px;
  font-size: 10px;
  color: #6c757d;
}

.list-dropdown ul {
  list-style: none;
  /* Fixas IE 8 */
}

@media (min-width: 992px) {
  .list-dropdown,
.list-dropdown-desktop {
    position: relative;
  }
  .list-dropdown::after,
.list-dropdown-desktop::after {
    display: block;
    clear: both;
    content: "";
  }
  .list-dropdown > .title,
.list-dropdown-desktop > .title {
    cursor: pointer;
    color: #495057;
    text-decoration: none;
    position: relative;
    padding-right: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .list-dropdown > .title:hover,
.list-dropdown-desktop > .title:hover {
    color: #212529;
  }
  .list-dropdown > .title:after,
.list-dropdown-desktop > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e909";
    position: absolute;
  }
  .list-dropdown.no-carret > .title,
.list-dropdown-desktop.no-carret > .title {
    padding-right: 0;
  }
  .list-dropdown.no-carret > .title:after,
.list-dropdown-desktop.no-carret > .title:after {
    display: none;
  }
  .list-dropdown > .title + ul,
.list-dropdown > .title + div,
.list-dropdown-desktop > .title + ul,
.list-dropdown-desktop > .title + div {
    position: absolute;
    top: auto;
    left: 0px;
    visibility: hidden;
    margin: 0;
    opacity: 0;
    display: block;
    transition: opacity 0.3s, visibility 0.3s;
  }
  .list-dropdown > .title + ul[data-dropdown-align=right],
.list-dropdown > .title + div[data-dropdown-align=right],
.list-dropdown-desktop > .title + ul[data-dropdown-align=right],
.list-dropdown-desktop > .title + div[data-dropdown-align=right] {
    left: auto;
    right: 0;
  }
  .list-dropdown > ul, .list-dropdown .submenu_list > ul,
.list-dropdown-desktop > ul,
.list-dropdown-desktop .submenu_list > ul {
    z-index: 1000;
    float: left;
    padding: 5px 0;
    list-style: none;
    /*xxx*/
    text-align: left;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    background-clip: padding-box;
  }
  .list-dropdown > ul .divider, .list-dropdown .submenu_list > ul .divider,
.list-dropdown-desktop > ul .divider,
.list-dropdown-desktop .submenu_list > ul .divider {
    height: 1px;
    margin: -0.285714285 0;
    overflow: hidden;
    background-color: #e9ecef;
    margin: 5px 0;
  }
  .list-dropdown .list-dropdown > .title, .list-dropdown > ul > li > a, .list-dropdown .submenu_list > ul > li > a,
.list-dropdown .list-dropdown-desktop > .title,
.list-dropdown-desktop > ul > li > a,
.list-dropdown-desktop .submenu_list > ul > li > a,
.list-dropdown-desktop .list-dropdown > .title,
.list-dropdown-desktop .list-dropdown-desktop > .title {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #212529;
    white-space: nowrap;
  }
  .list-dropdown .list-dropdown > .title:hover, .list-dropdown > ul > li > a:hover, .list-dropdown .submenu_list > ul > li > a:hover,
.list-dropdown .list-dropdown-desktop > .title:hover,
.list-dropdown-desktop > ul > li > a:hover,
.list-dropdown-desktop .submenu_list > ul > li > a:hover,
.list-dropdown-desktop .list-dropdown > .title:hover,
.list-dropdown-desktop .list-dropdown-desktop > .title:hover {
    text-decoration: none;
    color: #16181b;
    background-color: #f8f9fa;
  }
  .list-dropdown.hover > .title + *,
.list-dropdown-desktop.hover > .title + * {
    display: block;
    visibility: visible;
    opacity: 1;
  }
  .list-dropdown .list-dropdown > .title,
.list-dropdown .list-dropdown-desktop > .title,
.list-dropdown-desktop .list-dropdown > .title,
.list-dropdown-desktop .list-dropdown-desktop > .title {
    padding-right: 35px;
  }
  .list-dropdown .list-dropdown > .title:after,
.list-dropdown .list-dropdown-desktop > .title:after,
.list-dropdown-desktop .list-dropdown > .title:after,
.list-dropdown-desktop .list-dropdown-desktop > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e802";
    position: absolute;
    top: 0;
    right: 10px;
    padding: 3px 0;
  }
  .list-dropdown .list-dropdown.hover > ul,
.list-dropdown .list-dropdown-desktop.hover > ul,
.list-dropdown-desktop .list-dropdown.hover > ul,
.list-dropdown-desktop .list-dropdown-desktop.hover > ul {
    left: 100%;
    top: -6px;
  }

  .list-collapse > .title,
.list-collapse-desktop > .title {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    padding-right: 30px;
  }
  .list-collapse > .title:after,
.list-collapse-desktop > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e909";
    position: absolute;
  }
  .list-collapse .title + *,
.list-collapse-desktop .title + * {
    display: none;
  }
  .list-collapse.open > .title:after,
.list-collapse-desktop.open > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e90a";
  }
  .list-collapse.open > .title + *,
.list-collapse-desktop.open > .title + * {
    display: block;
  }
}
@media (max-width: 992px) {
  .list-dropdown:not(.list-collapse-mobile),
.list-dropdown-mobile {
    position: relative;
  }
  .list-dropdown:not(.list-collapse-mobile)::after,
.list-dropdown-mobile::after {
    display: block;
    clear: both;
    content: "";
  }
  .list-dropdown:not(.list-collapse-mobile) > .title,
.list-dropdown-mobile > .title {
    cursor: pointer;
    color: #495057;
    text-decoration: none;
    position: relative;
    padding-right: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .list-dropdown:not(.list-collapse-mobile) > .title:hover,
.list-dropdown-mobile > .title:hover {
    color: #212529;
  }
  .list-dropdown:not(.list-collapse-mobile) > .title:after,
.list-dropdown-mobile > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e909";
    position: absolute;
  }
  .list-dropdown:not(.list-collapse-mobile).no-carret > .title,
.list-dropdown-mobile.no-carret > .title {
    padding-right: 0;
  }
  .list-dropdown:not(.list-collapse-mobile).no-carret > .title:after,
.list-dropdown-mobile.no-carret > .title:after {
    display: none;
  }
  .list-dropdown:not(.list-collapse-mobile) > .title + ul,
.list-dropdown:not(.list-collapse-mobile) > .title + div,
.list-dropdown-mobile > .title + ul,
.list-dropdown-mobile > .title + div {
    position: absolute;
    top: auto;
    left: 0px;
    visibility: hidden;
    margin: 0;
    opacity: 0;
    display: block;
    transition: opacity 0.3s, visibility 0.3s;
  }
  .list-dropdown:not(.list-collapse-mobile) > .title + ul[data-dropdown-align=right],
.list-dropdown:not(.list-collapse-mobile) > .title + div[data-dropdown-align=right],
.list-dropdown-mobile > .title + ul[data-dropdown-align=right],
.list-dropdown-mobile > .title + div[data-dropdown-align=right] {
    left: auto;
    right: 0;
  }
  .list-dropdown:not(.list-collapse-mobile) > ul, .list-dropdown:not(.list-collapse-mobile) .submenu_list > ul,
.list-dropdown-mobile > ul,
.list-dropdown-mobile .submenu_list > ul {
    z-index: 1000;
    float: left;
    padding: 5px 0;
    list-style: none;
    /*xxx*/
    text-align: left;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    background-clip: padding-box;
  }
  .list-dropdown:not(.list-collapse-mobile) > ul .divider, .list-dropdown:not(.list-collapse-mobile) .submenu_list > ul .divider,
.list-dropdown-mobile > ul .divider,
.list-dropdown-mobile .submenu_list > ul .divider {
    height: 1px;
    margin: -0.285714285 0;
    overflow: hidden;
    background-color: #e9ecef;
    margin: 5px 0;
  }
  .list-dropdown:not(.list-collapse-mobile) .list-dropdown:not(.list-collapse-mobile) > .title, .list-dropdown:not(.list-collapse-mobile) > ul > li > a, .list-dropdown:not(.list-collapse-mobile) .submenu_list > ul > li > a,
.list-dropdown:not(.list-collapse-mobile) .list-dropdown-mobile > .title,
.list-dropdown-mobile > ul > li > a,
.list-dropdown-mobile .submenu_list > ul > li > a,
.list-dropdown-mobile .list-dropdown:not(.list-collapse-mobile) > .title,
.list-dropdown-mobile .list-dropdown-mobile > .title {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #212529;
    white-space: nowrap;
  }
  .list-dropdown:not(.list-collapse-mobile) .list-dropdown:not(.list-collapse-mobile) > .title:hover, .list-dropdown:not(.list-collapse-mobile) > ul > li > a:hover, .list-dropdown:not(.list-collapse-mobile) .submenu_list > ul > li > a:hover,
.list-dropdown:not(.list-collapse-mobile) .list-dropdown-mobile > .title:hover,
.list-dropdown-mobile > ul > li > a:hover,
.list-dropdown-mobile .submenu_list > ul > li > a:hover,
.list-dropdown-mobile .list-dropdown:not(.list-collapse-mobile) > .title:hover,
.list-dropdown-mobile .list-dropdown-mobile > .title:hover {
    text-decoration: none;
    color: #16181b;
    background-color: #f8f9fa;
  }
  .list-dropdown:not(.list-collapse-mobile).hover > .title + *,
.list-dropdown-mobile.hover > .title + * {
    display: block;
    visibility: visible;
    opacity: 1;
  }
  .list-dropdown:not(.list-collapse-mobile) .list-dropdown:not(.list-collapse-mobile) > .title,
.list-dropdown:not(.list-collapse-mobile) .list-dropdown-mobile > .title,
.list-dropdown-mobile .list-dropdown:not(.list-collapse-mobile) > .title,
.list-dropdown-mobile .list-dropdown-mobile > .title {
    padding-right: 35px;
  }
  .list-dropdown:not(.list-collapse-mobile) .list-dropdown:not(.list-collapse-mobile) > .title:after,
.list-dropdown:not(.list-collapse-mobile) .list-dropdown-mobile > .title:after,
.list-dropdown-mobile .list-dropdown:not(.list-collapse-mobile) > .title:after,
.list-dropdown-mobile .list-dropdown-mobile > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e802";
    position: absolute;
    top: 0;
    right: 10px;
    padding: 3px 0;
  }
  .list-dropdown:not(.list-collapse-mobile) .list-dropdown:not(.list-collapse-mobile).hover > ul,
.list-dropdown:not(.list-collapse-mobile) .list-dropdown-mobile.hover > ul,
.list-dropdown-mobile .list-dropdown:not(.list-collapse-mobile).hover > ul,
.list-dropdown-mobile .list-dropdown-mobile.hover > ul {
    left: 100%;
    top: -6px;
  }

  .list-collapse > .title,
.list-collapse-mobile > .title {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    padding-right: 30px;
  }
  .list-collapse > .title:after,
.list-collapse-mobile > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e909";
    position: absolute;
  }
  .list-collapse .title + *,
.list-collapse-mobile .title + * {
    display: none;
  }
  .list-collapse.open > .title:after,
.list-collapse-mobile.open > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e90a";
  }
  .list-collapse.open > .title + *,
.list-collapse-mobile.open > .title + * {
    display: block;
  }
  .list-collapse .title, .list-collapse li > a:not(.keep-as-desktop),
.list-collapse-mobile .title,
.list-collapse-mobile li > a:not(.keep-as-desktop) {
    line-height: 20px;
    margin: 0;
    display: block;
    text-decoration: none;
    color: #495057;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
  }
  .list-collapse .title:hover, .list-collapse li > a:not(.keep-as-desktop):hover,
.list-collapse-mobile .title:hover,
.list-collapse-mobile li > a:not(.keep-as-desktop):hover {
    background: #f8f9fa;
  }
  .list-collapse .title > h2, .list-collapse .title > h3, .list-collapse .title > h4, .list-collapse .title > h5, .list-collapse li > a:not(.keep-as-desktop) > h2, .list-collapse li > a:not(.keep-as-desktop) > h3, .list-collapse li > a:not(.keep-as-desktop) > h4, .list-collapse li > a:not(.keep-as-desktop) > h5,
.list-collapse-mobile .title > h2,
.list-collapse-mobile .title > h3,
.list-collapse-mobile .title > h4,
.list-collapse-mobile .title > h5,
.list-collapse-mobile li > a:not(.keep-as-desktop) > h2,
.list-collapse-mobile li > a:not(.keep-as-desktop) > h3,
.list-collapse-mobile li > a:not(.keep-as-desktop) > h4,
.list-collapse-mobile li > a:not(.keep-as-desktop) > h5 {
    margin: 0;
  }
  .list-collapse .title > .check, .list-collapse li > a:not(.keep-as-desktop) > .check,
.list-collapse-mobile .title > .check,
.list-collapse-mobile li > a:not(.keep-as-desktop) > .check {
    margin-top: 2px;
  }
}
@media (max-width: 992px) and (max-width: 992px) {
  .list-collapse .title, .list-collapse li > a:not(.keep-as-desktop),
.list-collapse-mobile .title,
.list-collapse-mobile li > a:not(.keep-as-desktop) {
    background-color: #fff;
  }
  .list-collapse .title:hover, .list-collapse li > a:not(.keep-as-desktop):hover,
.list-collapse-mobile .title:hover,
.list-collapse-mobile li > a:not(.keep-as-desktop):hover {
    background-color: #fff;
  }
}
@media (max-width: 992px) {
  .list-collapse > .title,
.list-collapse-mobile > .title {
    padding-right: 40px;
  }
  .list-collapse > .title:after,
.list-collapse-mobile > .title:after {
    top: 10px;
    right: 10px;
    font-size: 18px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e802";
  }
}
@media (max-width: 992px) {
  .list-collapse.open > .title:after,
.list-collapse-mobile.open > .title:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e909";
  }
}
@media (max-width: 992px) {
  .list-collapse ul,
.list-collapse-mobile ul {
    margin: 0;
    position: static;
    list-style: none;
    padding: 0;
    background: #f8f9fa;
  }
  .list-collapse ul.dropdown-menu,
.list-collapse-mobile ul.dropdown-menu {
    position: static;
    float: none;
    box-shadow: none;
    border: none;
  }
  .list-collapse ul .title,
.list-collapse-mobile ul .title {
    color: #000;
  }
  .list-collapse ul .title, .list-collapse ul a,
.list-collapse-mobile ul .title,
.list-collapse-mobile ul a {
    padding-left: 20px;
    border-bottom: 1px solid #ced4da;
  }
  .list-collapse ul .title:hover, .list-collapse ul a:hover,
.list-collapse-mobile ul .title:hover,
.list-collapse-mobile ul a:hover {
    background: #f8f9fa;
  }
  .list-collapse ul .list-default > li,
.list-collapse-mobile ul .list-default > li {
    color: #123456;
    padding-left: 0;
  }
  .list-collapse ul .list-default > li:before,
.list-collapse-mobile ul .list-default > li:before {
    display: none;
  }
}
@media (max-width: 992px) {
  .list-collapse .list-collapse ul,
.list-collapse .list-collapse-mobile ul,
.list-collapse-mobile .list-collapse ul,
.list-collapse-mobile .list-collapse-mobile ul {
    background: #f8f9fa;
  }
  .list-collapse .list-collapse ul .title, .list-collapse .list-collapse ul a,
.list-collapse .list-collapse-mobile ul .title,
.list-collapse .list-collapse-mobile ul a,
.list-collapse-mobile .list-collapse ul .title,
.list-collapse-mobile .list-collapse ul a,
.list-collapse-mobile .list-collapse-mobile ul .title,
.list-collapse-mobile .list-collapse-mobile ul a {
    padding-left: 30px;
    border-bottom: 1px solid #adb5bd;
  }
  .list-collapse .list-collapse ul .title:hover, .list-collapse .list-collapse ul a:hover,
.list-collapse .list-collapse-mobile ul .title:hover,
.list-collapse .list-collapse-mobile ul a:hover,
.list-collapse-mobile .list-collapse ul .title:hover,
.list-collapse-mobile .list-collapse ul a:hover,
.list-collapse-mobile .list-collapse-mobile ul .title:hover,
.list-collapse-mobile .list-collapse-mobile ul a:hover {
    background: #e9ecef;
  }
}
@media (max-width: 992px) {
  .list-collapse .list-collapse .list-collapse ul,
.list-collapse .list-collapse .list-collapse-mobile ul,
.list-collapse .list-collapse-mobile .list-collapse ul,
.list-collapse .list-collapse-mobile .list-collapse-mobile ul,
.list-collapse-mobile .list-collapse .list-collapse ul,
.list-collapse-mobile .list-collapse .list-collapse-mobile ul,
.list-collapse-mobile .list-collapse-mobile .list-collapse ul,
.list-collapse-mobile .list-collapse-mobile .list-collapse-mobile ul {
    background: #e9ecef;
  }
  .list-collapse .list-collapse .list-collapse ul .title, .list-collapse .list-collapse .list-collapse ul a,
.list-collapse .list-collapse .list-collapse-mobile ul .title,
.list-collapse .list-collapse .list-collapse-mobile ul a,
.list-collapse .list-collapse-mobile .list-collapse ul .title,
.list-collapse .list-collapse-mobile .list-collapse ul a,
.list-collapse .list-collapse-mobile .list-collapse-mobile ul .title,
.list-collapse .list-collapse-mobile .list-collapse-mobile ul a,
.list-collapse-mobile .list-collapse .list-collapse ul .title,
.list-collapse-mobile .list-collapse .list-collapse ul a,
.list-collapse-mobile .list-collapse .list-collapse-mobile ul .title,
.list-collapse-mobile .list-collapse .list-collapse-mobile ul a,
.list-collapse-mobile .list-collapse-mobile .list-collapse ul .title,
.list-collapse-mobile .list-collapse-mobile .list-collapse ul a,
.list-collapse-mobile .list-collapse-mobile .list-collapse-mobile ul .title,
.list-collapse-mobile .list-collapse-mobile .list-collapse-mobile ul a {
    padding-left: 45px;
    border-bottom: 1px solid #adb5bd;
  }
  .list-collapse .list-collapse .list-collapse ul .title:hover, .list-collapse .list-collapse .list-collapse ul a:hover,
.list-collapse .list-collapse .list-collapse-mobile ul .title:hover,
.list-collapse .list-collapse .list-collapse-mobile ul a:hover,
.list-collapse .list-collapse-mobile .list-collapse ul .title:hover,
.list-collapse .list-collapse-mobile .list-collapse ul a:hover,
.list-collapse .list-collapse-mobile .list-collapse-mobile ul .title:hover,
.list-collapse .list-collapse-mobile .list-collapse-mobile ul a:hover,
.list-collapse-mobile .list-collapse .list-collapse ul .title:hover,
.list-collapse-mobile .list-collapse .list-collapse ul a:hover,
.list-collapse-mobile .list-collapse .list-collapse-mobile ul .title:hover,
.list-collapse-mobile .list-collapse .list-collapse-mobile ul a:hover,
.list-collapse-mobile .list-collapse-mobile .list-collapse ul .title:hover,
.list-collapse-mobile .list-collapse-mobile .list-collapse ul a:hover,
.list-collapse-mobile .list-collapse-mobile .list-collapse-mobile ul .title:hover,
.list-collapse-mobile .list-collapse-mobile .list-collapse-mobile ul a:hover {
    background: #e9ecef;
  }
}
.news_element > a > .placeholder, .product_element > a > .placeholder {
  display: block;
  background: #dee2e6;
  padding-bottom: 56.25%;
}

.pagination-wrp .lazy {
  float: left;
}
.pagination-wrp .page_sizes {
  float: right;
}
.pagination-wrp .page_sizes a {
  display: inline-block;
  margin-left: 5px;
}
.pagination-wrp .page_sizes a.active {
  font-weight: bold;
}
.pagination-wrp .lazy, .pagination-wrp .page_sizes {
  margin: 20px 0;
  line-height: 34px;
}
.pagination-wrp, .pagination-wrp .pages {
  text-align: center;
}

.quantity_control {
  width: 120px;
}
.quantity_control input[type=text] {
  padding: 6px;
  height: 38px;
}
.quantity_control button {
  padding: 6px;
  width: 38px;
  margin-top: 2px;
}

*[data-ajaxnav=true]:not(form) {
  cursor: pointer;
}

.form-wrapper, .content-box {
  background: #fff;
  border: 1px solid #e9ecef;
  padding: 30px 30px 15px 30px;
  margin: 20px 0;
}
@media (max-width: 320px) {
  .form-wrapper, .content-box {
    padding: 20px 20px 10px 20px;
  }
}
.form-wrapper::after, .content-box::after {
  display: block;
  clear: both;
  content: "";
}
.form-wrapper > *:first-child,
.form-wrapper > *:first-child > *:first-child,
.form-wrapper > *:first-child > *:first-child > *:first-child, .content-box > *:first-child,
.content-box > *:first-child > *:first-child,
.content-box > *:first-child > *:first-child > *:first-child {
  margin-top: 0;
}
.form-wrapper > *:last-child, .content-box > *:last-child {
  margin-bottom: 15px;
}

.form-wrapper .radio input, .form-wrapper .form-check input {
  cursor: pointer;
}
.form-wrapper .radio label.control-label, .form-wrapper .form-check label.control-label {
  padding-top: 0;
}
.form-wrapper .radio p.help-block, .form-wrapper .form-check p.help-block {
  padding-left: 5px;
  margin: 0.5em 0;
}
.form-horizontal .form-wrapper .form-group > .control-label {
  text-align: left;
}
.form-horizontal .form-wrapper .form-group > .control-message {
  padding-top: 7px;
}
.form-wrapper .form-group-lg .radio label.control-label, .form-wrapper .form-group-lg .form-check label.control-label {
  padding-left: 15px;
  padding-top: 0px;
}
.form-wrapper .form-group-lg .radio label.control-label::before, .form-wrapper .form-group-lg .form-check label.control-label::before {
  width: 21px;
  height: 21px;
}
.form-wrapper .form-group-lg .radio label.control-label::after, .form-wrapper .form-group-lg .form-check label.control-label::after {
  width: 13px;
  height: 13px;
  left: 4px;
  top: 4px;
}
.form-wrapper .form-group-lg .radio label.control-label + p.help-block, .form-wrapper .form-group-lg .form-check label.control-label + p.help-block {
  padding-left: 15px;
}
.form-wrapper .form-group .control-message.error {
  display: none;
  color: #dc3545;
}
.form-wrapper .form-group.has-error .control-message.error {
  display: block;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#ajax_loader {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}
#ajax_loader:before {
  position: absolute;
  margin: 0;
  left: 50%;
  top: 50%;
  margin-left: -75px;
  margin-top: -75px;
  display: block;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  content: "\e901";
  font-size: 150px;
  color: #d3408f;
}
#ajax_loader > span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  text-align: center;
  line-height: 100px;
  display: inline-block;
  font-size: 50px;
  margin-top: -50px;
  margin-left: -50px;
}

#scrollup {
  position: fixed;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  z-index: 4;
}
#scrollup:hover {
  background: rgba(0, 0, 0, 0.7);
}
#scrollup .icon, #scrollup .icon:before {
  display: block;
  width: 40px;
  margin: 0;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
}

.text-center > img {
  margin: 0 auto;
}

.ajax_overlay {
  background: #fff url("/public/images/frontend/ajax-loader.gif") no-repeat 50% 50%;
  filter: alpha(opacity=75);
  -moz-opacity: 0.75;
  opacity: 0.75;
  position: absolute;
  z-index: 20;
}

.overlay {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
  display: none;
  background-color: black;
  opacity: 0.5;
}

.photos_listing {
  margin: 15px -10px;
}
.photos_listing::after {
  display: block;
  clear: both;
  content: "";
}
.photos_listing a {
  float: left;
  width: 25%;
  padding: 10px;
}
@media (max-width: 576px) {
  .photos_listing a {
    width: 33.33%;
  }
}
.photos_listing a img {
  width: 100%;
}

#lazy_loader {
  text-align: center;
  font-size: 50px;
  display: none;
}

.vcenter {
  white-space: nowrap;
}
.vcenter > * {
  word-spacing: normal;
}
.vcenter:before {
  content: "";
  height: 100%;
}
.vcenter:before, .vcenter > * {
  display: inline-block;
  vertical-align: middle;
}

.alert-success {
  margin-top: 10px;
}

@media (max-width: 576px) {
  .modal .modal-dialog {
    width: auto;
  }
}

@media (max-width: 380px) {
  .buttons_group {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .fancybox-content {
    width: 70%;
  }
}

.btn-custom {
  min-width: 156px;
  padding: 8px 12px;
  text-transform: uppercase;
  border: 2px solid #000;
  font-weight: 500;
  background: transparent;
  color: #000;
}
.btn-custom:hover {
  background: #000;
  color: #fff;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none;
}

.pagination-wrp {
  border-top: 1px solid #e9ecef;
  margin-top: 1em;
}

.radio input[type=radio]:focus + label::before {
  outline: none;
}

.menu-bar {
  width: 20px;
  height: 2px;
  background-color: #343a40;
  margin: 4px 0;
  transition: 0.4s;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-family: "Calibre", sans-serif;
}

h1, .h1 {
  font-size: 28px;
  line-height: 32px;
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 50px;
    line-height: 48px;
  }
}

h2, .h2 {
  margin-bottom: 24px;
  font-size: 28px;
  line-height: 32px;
}
@media (min-width: 768px) {
  h2, .h2 {
    margin-bottom: 38px;
    font-size: 38px;
    line-height: 48px;
  }
}

ol {
  padding-left: 13px;
}
ol li {
  margin-bottom: 22px;
  padding-left: 11px;
}
@media (min-width: 768px) {
  ol {
    padding-left: 13px;
  }
  ol li {
    margin-bottom: 26px;
    padding-left: 29px;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.link-wt-shadow {
  padding: 7px 0px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.13em;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  box-shadow: inset 0 0.15em transparent, inset 0 -0.15em;
}
.link-wt-shadow:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .link-wt-shadow {
    font-size: 13px;
    line-height: 22px;
  }
}

nav {
  position: absolute;
  border-bottom: none;
  top: 85px;
  width: 100%;
  right: 0;
  z-index: 4;
  background-color: #fff;
  transition: all 0.8s ease;
}
@media (max-width: 992px) {
  nav {
    display: none;
  }
}
nav.nav-mobile {
  top: 50px;
  z-index: 201;
  height: 100%;
  overflow-x: hidden;
  display: block;
}
nav.nav-up {
  top: 85px;
}
@media (min-width: 992px) {
  nav h4 a {
    color: #fff;
  }
  nav h4:after {
    color: #fff;
  }
}
@media (max-width: 992px) {
  nav {
    display: none;
  }
}
nav #pages_mega_menu {
  clear: both;
}
#content_layout.fixed_menu nav #pages_mega_menu {
  clear: none;
}
@media (min-width: 992px) {
  nav #pages_mega_menu #mega_menu_overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    opacity: 0;
    transition: opacity 0.3s;
    background: #fff;
    top: 50px;
  }
  nav #pages_mega_menu #mega_menu_overlay.active {
    display: block;
    opacity: 0.8;
  }
}
@media (max-width: 992px) {
  nav #pages_mega_menu #mega_menu_overlay {
    display: none;
  }
}
@media (min-width: 992px) {
  nav #pages_mega_menu #mega_menu {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  nav #pages_mega_menu #mega_menu > li:not(.mega_element) {
    position: relative;
  }
  nav #pages_mega_menu #mega_menu > li > a,
nav #pages_mega_menu #mega_menu > li > a > h4,
nav #pages_mega_menu #mega_menu > li > .list-dropdown > .title,
nav #pages_mega_menu #mega_menu > li > .list-dropdown-desktop > .title {
    font-weight: 500;
    margin: 0;
    line-height: 50px;
    display: block;
  }
  nav #pages_mega_menu #mega_menu > li > a:hover,
nav #pages_mega_menu #mega_menu > li > .list-dropdown.hover > .title,
nav #pages_mega_menu #mega_menu > li > .list-dropdown-desktop.hover > .title {
    position: relative;
    text-decoration: none;
  }
  nav #pages_mega_menu #mega_menu > li > a:hover:before,
nav #pages_mega_menu #mega_menu > li > .list-dropdown.hover > .title:before,
nav #pages_mega_menu #mega_menu > li > .list-dropdown-desktop.hover > .title:before {
    content: "";
    position: absolute;
    height: 3px;
    background: #d3408f;
    left: 0;
    right: 0;
    bottom: 0px;
    transform-origin: 0% 50%;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown, nav #pages_mega_menu #mega_menu .list-dropdown-desktop {
    position: static;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_list {
    z-index: 3;
    background: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    padding-left: 0px;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list li, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_list li {
    width: 100%;
    position: relative;
    color: #d3408f;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list li.has_child:after, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_list li.has_child:after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e802";
    position: absolute;
    right: 0;
    top: 13px;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list li a, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_list li a {
    display: block;
    padding: 10px 20px;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list li a h4.level-2, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_list li a h4.level-2 {
    font-size: 14px;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list li > ul, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_list li > ul {
    top: 0;
    display: none;
    z-index: 2;
    width: 100%;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list li.hover > ul, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_list li.hover > ul {
    display: block;
    position: absolute;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega {
    width: 100%;
    background: #fff;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega > .banner, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega > .banner {
    position: relative;
    background: #d3408f;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega > .banner img, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega > .banner img {
    min-width: 100%;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega > .banner a.btn, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega > .banner a.btn {
    position: absolute;
    top: 50%;
    right: 25px;
    margin-top: -15px;
    font-weight: bold;
    background: transparent;
    transition: background-color 0.5s;
    border: 1px solid #fff;
    font-weight: 600;
    color: #fff;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega > .banner a.btn:hover, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega > .banner a.btn:hover {
    background: #fff;
    color: #343a40;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega > .banner .title, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega > .banner .title {
    line-height: 120px;
    font-size: 90px;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #fff;
    padding: 0 25px;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega .submenu_groups, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega .submenu_groups {
    white-space: normal;
    padding: 10px;
    margin: 0;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega .submenu_groups::after, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega .submenu_groups::after {
    display: block;
    clear: both;
    content: "";
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega .submenu_groups .menu_column, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega .submenu_groups .menu_column {
    margin: 15px 0;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega .submenu_groups .menu_column li.menu_item_banner, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega .submenu_groups .menu_column li.menu_item_banner {
    padding-left: 0;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_mega .submenu_groups .menu_column li.menu_item_banner:before, nav #pages_mega_menu #mega_menu .list-dropdown-desktop .submenu_mega .submenu_groups .menu_column li.menu_item_banner:before {
    display: none;
  }
  #content_layout.fixed_menu nav #pages_mega_menu #mega_menu {
    margin-left: 0;
    margin-right: 150px;
  }
}
@media (max-width: 992px) {
  nav #pages_mega_menu #mega_menu .list-dropdown .profile {
    padding-right: 0;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .profile .icon-profile {
    padding-right: 14px;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .profile:after {
    content: none;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list, nav #pages_mega_menu #mega_menu .list-dropdown .level-3 {
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 100%;
    right: 0;
    z-index: 10;
    width: 100%;
    transition: left 0.5s;
    display: block;
    padding: 0;
    background: #fff;
    overflow-y: scroll;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list ul, nav #pages_mega_menu #mega_menu .list-dropdown .level-3 ul {
    margin: 0 10px;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list ul li h4.level-2, nav #pages_mega_menu #mega_menu .list-dropdown .level-3 ul li h4.level-2 {
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    padding: 2.5px 0;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list ul li h4.title, nav #pages_mega_menu #mega_menu .list-dropdown .level-3 ul li h4.title {
    padding: 10px;
    text-align: center;
    padding-right: 9px;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list .menu_column.open .level-3, nav #pages_mega_menu #mega_menu .list-dropdown .level-3 .menu_column.open .level-3 {
    left: 0;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown .submenu_list .menu_column.open .level-3 .col-md-1, nav #pages_mega_menu #mega_menu .list-dropdown .level-3 .menu_column.open .level-3 .col-md-1 {
    padding-right: 0;
  }
  nav #pages_mega_menu #mega_menu .list-dropdown.open > .submenu_list {
    left: 0;
  }
  nav #pages_mega_menu #mega_menu .list-collapse-mobile a {
    color: #000;
  }
  nav #pages_mega_menu #mega_menu .list-collapse-mobile.level-2 h4.title {
    border-bottom: 1px solid #dee2e6;
  }
  nav #pages_mega_menu #mega_menu .list-collapse-mobile.level-2 h4.title:link, nav #pages_mega_menu #mega_menu .list-collapse-mobile.level-2 h4.title:visited, nav #pages_mega_menu #mega_menu .list-collapse-mobile.level-2 h4.title:hover, nav #pages_mega_menu #mega_menu .list-collapse-mobile.level-2 h4.title:active {
    background: #fff;
  }
  nav #pages_mega_menu #mega_menu .list-collapse-mobile.level-2 h4:after {
    content: "\e801";
    left: 0;
    margin-left: 0.3em;
  }
  nav #pages_mega_menu #mega_menu .list-collapse-mobile .level-3 ul li a {
    padding: 10px;
  }
  nav #pages_mega_menu #mega_menu li > a > .title {
    color: #000;
  }
  nav #pages_mega_menu #mega_menu li > a {
    color: #495057;
    text-decoration: none;
  }
  nav #pages_mega_menu #mega_menu li > a:hover {
    color: #212529;
  }
  nav #pages_mega_menu #mega_menu li > a > .title {
    line-height: 20px;
    margin: 0;
    display: block;
    text-decoration: none;
    color: #495057;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
  }
  nav #pages_mega_menu #mega_menu li > a > .title:hover {
    background: #f8f9fa;
  }
  nav #pages_mega_menu #mega_menu li > a > .title > h2, nav #pages_mega_menu #mega_menu li > a > .title > h3, nav #pages_mega_menu #mega_menu li > a > .title > h4, nav #pages_mega_menu #mega_menu li > a > .title > h5 {
    margin: 0;
  }
  nav #pages_mega_menu #mega_menu li > a > .title > .check {
    margin-top: 2px;
  }
}
@media (max-width: 992px) and (max-width: 992px) {
  nav #pages_mega_menu #mega_menu li > a > .title {
    background-color: #fff;
  }
  nav #pages_mega_menu #mega_menu li > a > .title:hover {
    background-color: #fff;
  }
}
nav #pages_mega_menu #mega_menu > li {
  color: #fff;
  margin: 0 !important;
}
nav #pages_mega_menu #mega_menu > li > a > h4.title,
nav #pages_mega_menu #mega_menu > li > div > h4.title {
  color: #fff;
}
@media (max-width: 992px) {
  nav #pages_mega_menu #mega_menu > li > a > h4.title,
nav #pages_mega_menu #mega_menu > li > div > h4.title {
    color: #000;
  }
}
nav #pages_mega_menu #mega_menu > li > a > .title, nav #pages_mega_menu #mega_menu > li > div > .title {
  text-transform: uppercase;
  font-size: 14px;
}
nav #pages_mega_menu #mega_menu h5.title {
  color: #000;
}
nav #pages_mega_menu #mega_menu .submenu_list a {
  display: block;
}
nav #pages_mega_menu #mega_menu .submenu_list .eq-height {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  nav #pages_mega_menu #mega_menu .submenu_list .eq-height {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  nav #pages_mega_menu #mega_menu .submenu_list .eq-height .menu_column.has_child_list a.child span:before, nav #pages_mega_menu #mega_menu .submenu_list .eq-height .menu_column.has_child_list a.subchild span:before {
    content: "-";
    display: inline-block;
    margin-right: 8px;
    color: #d3408f;
  }
  nav #pages_mega_menu #mega_menu .submenu_list .eq-height .menu_column.has_child_list a.child {
    padding: 10px;
  }
  nav #pages_mega_menu #mega_menu .submenu_list .eq-height .menu_column.has_child_list a.child:last-child {
    border-bottom: 1px solid #dee2e6;
  }
}
@media (min-width: 992px) {
  nav #pages_mega_menu {
    padding: 0 15px;
    background-color: #000;
  }
  nav #pages_mega_menu a:hover, nav #pages_mega_menu a:focus {
    color: #fff;
    text-decoration: none;
  }
  nav #pages_mega_menu .submenu_list li.has_child:after {
    color: #000;
  }
  nav #pages_mega_menu .submenu_list a {
    color: #000;
  }
  nav #pages_mega_menu .submenu_list a:hover, nav #pages_mega_menu .submenu_list a:focus {
    color: #000;
  }
  nav #pages_mega_menu .mega_element .submenu_list a:hover .level-2, nav #pages_mega_menu .mega_element .submenu_list a:focus .level-2 {
    text-decoration: underline;
  }
}
@media (max-width: 992px) {
  nav #pages_mega_menu .mega_element h4.mobile-head {
    color: #000;
    font-size: 15px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 0;
    padding-right: 25px;
    text-transform: uppercase;
    font-weight: 400;
  }
  nav #pages_mega_menu .mega_element h4.mobile-head:after {
    left: 0;
    font-size: 18px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e801";
    float: left;
    margin-left: 0.3em;
  }
  nav #pages_mega_menu .menu_column h5.title {
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }
  nav #pages_mega_menu .menu_column ul li.menu_item a {
    font-size: 15px;
    color: #000 !important;
    font-weight: 500;
  }
  nav #pages_mega_menu .menu_column ul li.menu_item h5.title {
    text-align: center;
    padding: 10px;
    padding-left: 0;
  }
  nav #pages_mega_menu .menu_column ul li.menu_item h5.title:link, nav #pages_mega_menu .menu_column ul li.menu_item h5.title:visited, nav #pages_mega_menu .menu_column ul li.menu_item h5.title:hover, nav #pages_mega_menu .menu_column ul li.menu_item h5.title:active {
    background: #fff;
  }
  nav #pages_mega_menu .menu_column ul li.menu_item h5.title:after {
    left: 0;
    font-size: 18px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    content: "\e802";
    float: left;
    margin-left: 0.2em;
  }
  nav #pages_mega_menu i.icon {
    float: right;
    font-size: 20px;
    padding-right: 4px;
  }
  nav #pages_mega_menu i.icon.no-float {
    float: none;
  }
}

header {
  width: 100%;
  height: 85px;
  position: absolute;
  background: #fff;
  z-index: 5;
}
header #logo {
  float: left;
  transition: all 0.3s;
  height: 100%;
}
header #logo a, header #logo img {
  height: 100%;
}
@media (min-width: 992px) {
  header #logo {
    z-index: 9;
    position: absolute;
    padding: 25px 0;
    margin: 0;
  }
  #content_layout.fixed_menu header #logo {
    display: none;
  }
  header #logo img {
    transition: all 0.3s;
  }
}
@media (max-width: 992px) {
  header #logo {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    width: auto;
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 0px;
    text-align: center;
  }
  header #logo a {
    display: block;
    height: auto;
  }
  header #logo img {
    height: 25px;
    width: auto;
  }
}
header #quick_search.active {
  position: absolute;
  background: #fff;
  width: 320px;
  right: 0;
  position: relative;
}
@media (max-width: 992px) {
  header #quick_search.active {
    width: 100%;
    left: 0px;
    margin: 0;
    padding: 10px 10px 5px 10px;
    right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  header #quick_search.active {
    width: 250px;
  }
}
#content_layout.fixed_menu header #quick_search.active {
  left: 0px;
}
@media (max-width: 992px) {
  #content_layout.fixed_menu header #quick_search.active {
    margin-top: 0;
  }
}
header #quick_search.active #quick_search_show {
  display: none;
}
header #quick_search.active form {
  display: block;
  border-bottom: 1px solid #000;
}
@media (max-width: 992px) {
  header #quick_search.active form {
    border-bottom: none;
  }
}
header #quick_search form {
  display: none;
  border-bottom: 1px solid #e9ecef;
  width: 100%;
}
header #quick_search input, header #quick_search button {
  box-shadow: none;
  border: none;
  background: none;
}
header #quick_search button {
  padding: 0;
}
header #languages_menu {
  float: right;
  margin-top: 25px;
  margin-right: 10px;
}
@media (max-width: 992px) {
  header #languages_menu {
    margin-top: 15px;
    margin-right: 5px;
  }
}
header #languages_menu .dropdown-toggle:after {
  display: none !important;
}
header #languages_menu li {
  list-style-type: none;
}
header #languages_menu li img {
  width: 25px;
}
header #languages_menu ul.dropdown-menu {
  min-width: auto;
  left: -10px !important;
}
header #languages_menu ul.dropdown-menu > li {
  padding: 0px;
}
header #languages_menu ul.dropdown-menu > li > a {
  padding: 0 10px;
}
header #languages_menu_mobile .list-collapse-mobile .title img, header #languages_menu_mobile .list-collapse-mobile .level-2 img {
  display: inline-block;
  margin-left: 10px;
  height: 20px;
  width: auto;
  vertical-align: sub;
}
@media (max-width: 992px) {
  header {
    height: 50px;
    box-shadow: 0 0 4px #343a40;
    position: fixed;
    z-index: 301;
  }
}
#content_layout.fixed_menu header {
  position: fixed;
  z-index: 5;
  transition: top 0.5s;
  top: -60px;
}
#content_layout.fixed_menu header.showing {
  top: 0;
}
header #burger-icon {
  position: relative;
  z-index: 1;
  margin-top: 15px;
  float: left;
  padding-right: 10px;
}
@media (min-width: 992px) {
  header #burger-icon {
    display: none;
  }
}
header > .container-fluid, header > .container-sm, header > .container-md, header > .container-lg, header > .container-xl {
  position: relative;
  height: 100%;
}
header > .container-fluid::after, header > .container-sm::after, header > .container-md::after, header > .container-lg::after, header > .container-xl::after {
  display: block;
  clear: both;
  content: "";
}
@media (min-width: 992px) {
  header #top_menu_block {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
  }
  header #top_menu_block::after {
    display: block;
    clear: both;
    content: "";
  }
  header #top_menu_block #menu_information {
    float: left;
  }
  header #top_menu_block #menu_functional {
    float: right;
  }
}
@media (min-width: 992px) {
  header #cart_info, header #profile_menu, header #quick_search, header #wishlist_info {
    margin-top: 25px;
  }
}
header #cart_info .icon, header #profile_menu .icon, header #quick_search .icon, header #wishlist_info .icon {
  color: #6c757d;
}
header #cart_info,
header #profile_menu,
header #quick_search,
header #wishlist_info {
  float: right;
  margin-top: 20px;
  z-index: 9;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  border-right: 0;
}
@media (max-width: 992px) {
  header #cart_info,
header #profile_menu,
header #quick_search,
header #wishlist_info {
    margin-top: 10px;
  }
}
#content_layout.fixed_menu header #cart_info,
#content_layout.fixed_menu header #profile_menu,
#content_layout.fixed_menu header #quick_search,
#content_layout.fixed_menu header #wishlist_info {
  margin-top: 10px;
}
header #cart_info a,
header #profile_menu a,
header #quick_search a,
header #wishlist_info a {
  display: inline-block;
  position: relative;
  color: #495057;
  text-decoration: none;
}
header #cart_info a:hover,
header #profile_menu a:hover,
header #quick_search a:hover,
header #wishlist_info a:hover {
  color: #212529;
}
header #cart_info .icon,
header #profile_menu .icon,
header #quick_search .icon,
header #wishlist_info .icon {
  font-size: 24px;
}
header #cart_info span.title,
header #profile_menu span.title,
header #quick_search span.title,
header #wishlist_info span.title {
  text-transform: uppercase;
  margin: 0 15px 0 10px;
}
header #cart_info span.title:hover,
header #profile_menu span.title:hover,
header #quick_search span.title:hover,
header #wishlist_info span.title:hover {
  text-decoration: underline;
}
header #cart_info em.cnt, header #wishlist_info em.cnt {
  text-align: center;
  font-style: normal;
  position: absolute;
  top: -8px;
  left: 20px;
  width: 20px;
  height: 20px;
  line-height: 18px;
  background-color: #d3408f;
  border: 1px solid white;
  color: white;
  font-size: 10px;
  font-weight: 500;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#breadcrumb {
  margin: 10px 0;
}
#breadcrumb i {
  font-style: normal;
}

.cookie_bar {
  height: 70px;
  background-color: #d3408f;
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
}
.cookie_bar p {
  margin: 28px 40px 17px 12px;
  width: auto;
  float: none;
  font-size: 12px;
  color: white;
}
.cookie_bar p a {
  color: white;
  text-decoration: underline;
  text-transform: lowercase;
}
.cookie_bar .close {
  float: right;
  width: 28px;
  height: 28px;
  background: url("/public/images/frontend/cookie_bar_close.png") no-repeat center center;
  margin-right: 10px;
  margin-top: 20px;
  cursor: pointer;
  opacity: 1;
}
@media (max-width: 992px) {
  .cookie_bar {
    height: auto;
  }
}

.cookie_bar ~ #footer {
  margin-bottom: 70px;
}

#footer {
  border-top: 1px solid #e9ecef;
  background: none;
}
#footer #subscribers_subscribe {
  margin: 45px 0 15px 0;
}
#footer #subscribers_subscribe h4 {
  color: #000;
  font-size: 14px;
  letter-spacing: 1px;
  vertical-align: top;
  margin: 12px 0;
}
@media (min-width: 768px) {
  #footer #subscribers_subscribe h4 {
    display: inline-block;
    margin-right: 50px;
  }
}
@media (max-width: 768px) {
  #footer #subscribers_subscribe h4 {
    text-align: center;
  }
}
@media (min-width: 768px) {
  #footer #subscribers_subscribe #newsletter_form {
    display: inline-block;
    max-width: 320px;
    vertical-align: top;
  }
}
#footer #subscribers_subscribe #newsletter_form .btn {
  padding: 7px 9px;
  color: #000;
  background-color: #fff;
  border-color: #dee2e6;
  border-left: none;
}
#footer #subscribers_subscribe #newsletter_form .btn .icon {
  font-size: 17px;
}
#footer #subscribers_subscribe .form-control {
  height: 40px;
  font-size: 15px;
  color: #000;
  background: #fff;
  border-color: #dee2e6;
  border-right: none;
  box-shadow: none;
}
#footer #subscribers_subscribe .alert {
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  color: #fff;
}
#footer #subscribers_subscribe .alert-danger {
  background: #fa0f3d;
}
#footer #subscribers_subscribe .alert-success {
  background: #5cb85c;
}
#footer #footer-advantages {
  margin: 20px 0 40px 0;
}
#footer #footer-advantages a {
  color: #000;
}
#footer #footer-advantages h2 {
  padding-bottom: 20px;
}
#footer #footer-advantages img {
  max-width: 150px;
  max-height: 70px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  #footer #footer-advantages img {
    max-width: 100px;
    margin-top: 20px;
  }
}
#footer #footer-advantages .title {
  font-size: 18px;
  font-weight: 400;
}
#footer .logo {
  padding: 8px 0;
}
@media (max-width: 768px) {
  #footer .logo {
    display: none;
  }
}
#footer .logo img {
  max-width: 150px;
  max-height: 150px;
}
#footer #footer_social {
  padding: 1em 0;
  text-align: left;
}
#footer #footer_social .social-icons {
  display: inline-block;
}
#footer #footer_social .social-icons a {
  color: #000;
}
@media (min-width: 768px) {
  #footer #pages_footer_menu {
    display: flex;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  #footer #pages_footer_menu .list-default {
    padding-right: 15%;
  }
  #footer #pages_footer_menu .list-default:last-child {
    padding-right: 0px;
  }
}
#footer #pages_footer_menu .list-default h4.title {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
@media (max-width: 768px) {
  #footer #pages_footer_menu .list-default h4.title {
    margin-top: 2em;
  }
}
#footer #pages_footer_menu .list-default ul li {
  margin: 10px 0;
  padding: 0;
}
#footer #pages_footer_menu .list-default ul li:before {
  display: none;
}
#footer #pages_footer_menu .list-default ul li a {
  color: #000;
}
#footer #copyright {
  padding: 30px 0px;
}
@media (min-width: 768px) {
  #footer #copyright {
    border-top-color: #dee2e6;
  }
}
#footer #copyright a {
  color: #000;
}
#footer #copyright .copyright {
  float: left;
  text-align: left;
}
#footer #copyright .credits {
  float: right;
  text-align: right;
}
#footer #copyright .copyright, #footer #copyright .credits {
  width: 50%;
  padding: 5px 15px;
  color: #000;
}
@media (max-width: 576px) {
  #footer #copyright .copyright, #footer #copyright .credits {
    width: 100%;
    float: none;
    text-align: left;
  }
}
@media (min-width: 992px) {
  #footer {
    padding-top: 0;
  }
}
#footer .footer-bottom {
  background: #e9ecef;
  margin-top: 30px;
}
#footer .footer-bottom .footer-menu-wrapper {
  padding: 45px 0;
}

.page_editor_template {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  padding-right: 28px;
  max-width: 1380px;
}
.page_editor_template.full_width {
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}
.page_editor_template.full_width .row, .page_editor_template.full_width .product_listing {
  margin-left: 0px;
  margin-right: 0px;
}
@media (min-width: 768px) {
  .page_editor_template {
    margin-bottom: 78px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.page_editor_banner_right > div:nth-child(1) {
  order: 2;
}
.page_editor_banner_right > div:nth-child(2) {
  order: 1;
}
@media (max-width: 991.98px) {
  .page_editor_banner_right {
    flex-direction: column-reverse;
  }
  .modal-body .page_editor_banner_right {
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .page_editor_banner_top > div.banner-wrp {
    order: 1;
  }
  .page_editor_banner_top > div.texts-wrp {
    order: 2;
  }
}

@media (max-width: 767.98px) {
  .page_editor_banner_bottom > div:nth-child(1) {
    order: 2;
  }
  .page_editor_banner_bottom > div:nth-child(2) {
    order: 1;
  }
}

.editor_type_text.editor_type_title_block {
  margin-bottom: 47px;
  padding: 21px 28px 30px 28px;
  font-weight: 300;
}
.editor_type_text.editor_type_title_block h1, .editor_type_text.editor_type_title_block .h1 {
  margin-bottom: 14px;
}
@media (max-width: 991.98px) {
  .editor_type_text h2, .editor_type_text .h2, .editor_type_text h3, .editor_type_text .h3 {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .editor_type_text.editor_type_title_block {
    margin-bottom: 75px;
    padding: 26px 28px 34px 28px;
  }
  .editor_type_text.editor_type_title_block h1, .editor_type_text.editor_type_title_block .h1 {
    margin-bottom: 19px;
  }
}

.editor_type_banner {
  margin-bottom: 61px;
  padding: 0px;
}
.editor_type_banner a:hover {
  text-decoration: none;
}
.editor_type_banner .banner-inner-wrp {
  display: flex;
  align-items: center;
  padding: 28px;
  min-height: 222px;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}
.editor_type_banner .banner-inner-wrp .banner-content-wrp {
  max-width: 70%;
}
.editor_type_banner .banner-inner-wrp .banner-content-wrp h2 {
  margin: 0px;
  margin-bottom: 10px;
  line-height: 30px;
  text-transform: initial;
  letter-spacing: 0.326068px;
}
.editor_type_banner .banner-inner-wrp .banner-content-wrp p {
  margin-bottom: 12px;
}
.editor_type_banner .banner-inner-wrp .banner-content-wrp button {
  margin-bottom: 15px;
  padding: 7px 0px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.13em;
  color: #000000;
  background-color: transparent;
  border: none;
  box-shadow: inset 0 0.15em transparent, inset 0 -0.15em #000000;
}
.editor_type_banner .banner-inner-wrp .banner-content-wrp.fonts-1 h2 {
  margin-bottom: 5px;
  font-size: 28px;
  line-height: 30px;
}
.editor_type_banner .banner-inner-wrp .banner-content-wrp.fonts-1 p {
  margin-bottom: 0px;
}
.editor_type_banner .banner-inner-wrp.ha-left {
  justify-content: flex-start;
}
.editor_type_banner .banner-inner-wrp.ha-center {
  justify-content: center;
}
.editor_type_banner .banner-inner-wrp.ha-right {
  justify-content: flex-end;
}
.editor_type_banner.ha-left .banner-inner-wrp {
  background-position: left center;
}
.editor_type_banner.ha-center .banner-inner-wrp {
  background-position: center;
}
.editor_type_banner.ha-right .banner-inner-wrp {
  background-position: right center;
}
@media (min-width: 768px) {
  .editor_type_banner {
    margin-bottom: 55px;
  }
  .editor_type_banner .banner-inner-wrp {
    padding: 70px;
    min-height: 540px;
  }
  .editor_type_banner .banner-inner-wrp .banner-content-wrp h2 {
    margin-bottom: 18px;
    font-size: 70px;
    line-height: 74px;
    letter-spacing: 0.793605px;
  }
  .editor_type_banner .banner-inner-wrp .banner-content-wrp p {
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 17px;
    line-height: 28px;
  }
  .editor_type_banner .banner-inner-wrp .banner-content-wrp button {
    margin-bottom: 0px;
    padding: 4px 0px;
    font-size: 13px;
    line-height: 22px;
  }
  .editor_type_banner .banner-inner-wrp .banner-content-wrp.fonts-1 h2 {
    margin-bottom: 19px;
    font-size: 50px;
    line-height: 48px;
    letter-spacing: 0px;
  }
  .editor_type_banner .banner-inner-wrp .banner-content-wrp.fonts-1 p {
    font-size: 14px;
    line-height: 26px;
  }
}

.editor_type_banner_text .inner-wrp {
  display: flex;
  flex-direction: column;
}
.editor_type_banner_text .inner-wrp a:hover {
  text-decoration: none;
}
.editor_type_banner_text .inner-wrp > div {
  flex-shrink: 0;
  width: 100%;
}
.editor_type_banner_text .inner-wrp .banner-wrp {
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 290px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.editor_type_banner_text .inner-wrp .banner-wrp.shadow {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3) !important;
}
.editor_type_banner_text .inner-wrp .texts-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 42px 32px;
  text-align: center;
}
.editor_type_banner_text .inner-wrp .texts-wrp h2 {
  margin-top: 0px;
  margin-bottom: 23px;
  text-transform: initial;
}
.editor_type_banner_text .inner-wrp .texts-wrp .text-wrp {
  margin-bottom: 24px;
}
.editor_type_banner_text .inner-wrp .texts-wrp.fonts-1 {
  line-height: 22px;
  font-size: 15px;
}
.editor_type_banner_text .inner-wrp .texts-wrp.margin-tb {
  margin: 0px 8px;
  width: calc(100% - 16px);
}
@media (max-width: 991.98px) {
  .editor_type_banner_text {
    padding-left: 14px;
    padding-right: 14px;
  }
}
@media (min-width: 768px) {
  .editor_type_banner_text {
    margin-bottom: 54px;
    padding: 0px;
  }
  .editor_type_banner_text .inner-wrp {
    flex-direction: row;
  }
  .editor_type_banner_text .inner-wrp > div {
    flex-shrink: 0;
    width: 50%;
  }
  .editor_type_banner_text .inner-wrp .banner-wrp {
    height: auto;
  }
  .editor_type_banner_text .inner-wrp .banner-wrp.wide {
    width: 58.3%;
  }
  .editor_type_banner_text .inner-wrp .texts-wrp {
    padding: 142px 32px;
    justify-content: center;
    text-align: left;
  }
  .editor_type_banner_text .inner-wrp .texts-wrp .text-column {
    max-width: 390px;
  }
  .editor_type_banner_text .inner-wrp .texts-wrp .text-wrp {
    margin-bottom: 30px;
  }
  .editor_type_banner_text .inner-wrp .texts-wrp.fonts-1 {
    line-height: 30px;
    font-size: 17px;
  }
  .editor_type_banner_text .inner-wrp .texts-wrp.margin-tb {
    margin: 28px 0px;
    width: 41.7%;
  }
}

.editor_type_features {
  overflow: hidden;
  margin-top: -39px;
  margin-bottom: 25px;
  padding: 40px;
}
.editor_type_features .inner-wrp .carousel-inner-x {
  display: flex;
  flex-direction: row;
}
.editor_type_features .inner-wrp .carousel-inner-x .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 28px 28px 30px 28px;
  min-height: 217px;
  width: 100%;
  text-align: center;
  background-color: #F9F9FB;
  transition: all 0.3s;
}
.editor_type_features .inner-wrp .carousel-inner-x .item .img-wrp {
  margin-bottom: 10px;
}
.editor_type_features .inner-wrp .carousel-inner-x .item .img-wrp img {
  margin: 0px auto;
  height: 40px;
  width: auto;
}
.editor_type_features .inner-wrp .carousel-inner-x .item h3 {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}
.editor_type_features .inner-wrp .carousel-inner-x .item p {
  margin-bottom: 0px;
  font-size: 14.189px;
  line-height: 18px;
}
.editor_type_features .inner-wrp .owl-carousel .owl-stage-outer {
  overflow: visible;
}
.editor_type_features .inner-wrp .owl-carousel .owl-stage-outer .owl-item.active {
  z-index: 1;
}
.editor_type_features .inner-wrp .owl-carousel .owl-stage-outer .owl-item.active .item {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
}
.editor_type_features .inner-wrp .owl-carousel .owl-dots {
  bottom: 11px;
}
.editor_type_features .inner-wrp .owl-carousel .owl-dots .owl-dot span {
  margin: 5px 4px;
  height: 8px;
  width: 8px;
  background: #C4C4C4;
  border: 1px solid #ffffff;
  transition: all 0.3s;
}
.editor_type_features .inner-wrp .owl-carousel .owl-dots .owl-dot.active span {
  background: #000000;
  border-color: #000000;
}
@media (min-width: 768px) {
  .editor_type_features {
    padding: 40px 0px;
  }
  .editor_type_features .inner-wrp {
    gap: 3px;
  }
  .editor_type_features .inner-wrp .carousel-inner-x .item {
    min-height: 416px;
    width: 33.3333333333%;
  }
  .editor_type_features .inner-wrp .carousel-inner-x .item .img-wrp {
    margin-bottom: 21px;
  }
  .editor_type_features .inner-wrp .carousel-inner-x .item .img-wrp img {
    height: 68px;
  }
  .editor_type_features .inner-wrp .carousel-inner-x .item h3 {
    margin-bottom: 3px;
    font-size: 22px;
    line-height: 26px;
  }
  .editor_type_features .inner-wrp .carousel-inner-x .item p {
    font-size: 17px;
    line-height: 22px;
  }
  .editor_type_features .inner-wrp .carousel-inner-x .item.active {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
  }
}

.editor_type_banner_banner {
  padding: 0px;
}
.editor_type_banner_banner a:hover {
  text-decoration: none;
}
.editor_type_banner_banner .inner-wrp {
  display: flex;
  flex-direction: column;
}
.editor_type_banner_banner .inner-wrp .banner-wrp .banner-inner-wrp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 28px;
  min-height: 280px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.editor_type_banner_banner .inner-wrp .banner-wrp .banner-inner-wrp h3 {
  margin: 0px 0px 12px 0px;
  font-weight: normal;
  font-size: 28px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .editor_type_banner_banner .inner-wrp {
    flex-direction: row;
  }
  .editor_type_banner_banner .inner-wrp .banner-wrp {
    width: 50%;
  }
  .editor_type_banner_banner .inner-wrp .banner-wrp .banner-inner-wrp {
    min-height: 564px;
  }
}

.editor_type_collection {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: -22px;
  padding: 0px 40px 40px 40px;
}
.editor_type_collection .collections_index_listing {
  max-width: 250px;
}
.editor_type_collection .collections_index_listing .collection h2 {
  margin-top: 0px;
  margin-bottom: 33px;
  text-transform: initial;
}
.editor_type_collection .collections_index_listing .collection .product_listing .product_element {
  padding: 0px 1px;
}
.editor_type_collection .collections_index_listing .collection .product_listing .product_element > a {
  padding: 30px 0px 49px 0px;
  width: 225px;
  background: #F5F5F9;
  transition: all 0.3s;
}
.editor_type_collection .collections_index_listing .collection .product_listing .product_element .title {
  margin-top: 0px;
  margin-bottom: 30px;
  height: auto;
}
.editor_type_collection .collections_index_listing .collection .product_listing .product_element .title h3 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
}
.editor_type_collection .collections_index_listing .collection .product_listing .product_element .title p {
  margin-bottom: 0px;
  font-weight: 300;
  font-size: 13px;
  line-height: 14px;
}
.editor_type_collection .collections_index_listing .collection .product_listing .owl-item.center {
  z-index: 1;
}
.editor_type_collection .collections_index_listing .collection .product_listing .owl-item.center .product_element > a {
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
}
.editor_type_collection .collections_index_listing .collection .owl-carousel .owl-stage-outer {
  overflow: visible;
}
@media (min-width: 768px) {
  .editor_type_collection {
    margin-top: 80px;
  }
  .editor_type_collection .collections_index_listing {
    max-width: 100%;
  }
  .editor_type_collection .collections_index_listing .collection .product-slider-container {
    position: relative;
    max-width: 1225px;
    margin: 0px auto !important;
    padding: 0px 30px;
  }
  .editor_type_collection .collections_index_listing .collection h2 {
    margin-bottom: 12px;
    font-size: 38px;
    line-height: 48px;
  }
  .editor_type_collection .collections_index_listing .collection .product_listing {
    margin: 0px auto !important;
    max-width: 1055px;
  }
  .editor_type_collection .collections_index_listing .collection .product_listing .product_element > a {
    padding: 44px 0px 35px 0px;
    width: 343px;
  }
  .editor_type_collection .collections_index_listing .collection .product_listing .product_element .title h3 {
    margin-bottom: 0px;
    font-size: 22px;
    line-height: 28px;
  }
  .editor_type_collection .collections_index_listing .collection .product_listing .product_element .title p {
    font-size: 14px;
    line-height: 22px;
  }
  .editor_type_collection .collections_index_listing .collection .owl-carousel {
    position: initial;
  }
  .editor_type_collection .collections_index_listing .collection .owl-carousel .owl-stage-outer {
    overflow: hidden;
    padding: 40px 0px;
  }
  .editor_type_collection .collections_index_listing .collection .owl-carousel .owl-nav {
    top: 50%;
  }
}

.editor_type_texts_banner {
  padding: 0px;
}
.editor_type_texts_banner .inner-wrp {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
}
.editor_type_texts_banner .inner-wrp .banner-wrp {
  grid-column: 1/2;
  grid-row: 2/3;
  position: relative;
  overflow: hidden;
  margin: -40px 27px 0px 27px;
  min-height: 290px;
}
.editor_type_texts_banner .inner-wrp .banner-wrp img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: initial;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.editor_type_texts_banner .inner-wrp .text-wrp.text-top-wrp {
  grid-column: 1/2;
  grid-row: 1/2;
  padding: 45px 28px 75px 28px;
  background-color: #F9F9FB;
}
.editor_type_texts_banner .inner-wrp .text-wrp.text-top-wrp h1 {
  margin-top: 0px;
  margin-bottom: 9px;
}
.editor_type_texts_banner .inner-wrp .text-wrp.text-top-wrp p {
  line-height: 20px;
}
.editor_type_texts_banner .inner-wrp .text-wrp.text-bottom-wrp {
  grid-column: 1/2;
  grid-row: 3/4;
  padding: 54px 28px 32px 28px;
}
@media (min-width: 768px) {
  .editor_type_texts_banner .inner-wrp {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
  }
  .editor_type_texts_banner .inner-wrp .banner-wrp {
    grid-column: 2/3;
    grid-row: 1/3;
    margin: 0px;
  }
  .editor_type_texts_banner .inner-wrp .banner-wrp img {
    width: auto;
  }
  .editor_type_texts_banner .inner-wrp .text-wrp.text-top-wrp {
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 96px 69px 79px 69px;
  }
  .editor_type_texts_banner .inner-wrp .text-wrp.text-top-wrp .inner-text-wrp {
    max-width: 380px;
  }
  .editor_type_texts_banner .inner-wrp .text-wrp.text-top-wrp h1 {
    margin-bottom: 19px;
  }
  .editor_type_texts_banner .inner-wrp .text-wrp.text-top-wrp p {
    line-height: 26px;
  }
  .editor_type_texts_banner .inner-wrp .text-wrp.text-bottom-wrp {
    grid-column: 1/2;
    grid-row: 2/3;
    padding: 101px 69px 75px 69px;
  }
  .editor_type_texts_banner .inner-wrp .text-wrp.text-bottom-wrp .inner-text-wrp {
    max-width: 500px;
  }
}

.editor_type_banners_slider {
  padding: 0px;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Calibre", sans-serif;
  font-size: 14px;
  background-color: #fff;
  color: #343a40;
}
body.main-nav-active {
  position: fixed;
  width: 100%;
}

#content_layout #content_wrapper {
  padding-top: 135px;
}
@media (max-width: 992px) {
  #content_layout #content_wrapper {
    padding-top: 60px;
  }
}
#content_layout #content_wrapper .content_body {
  padding-top: 0;
}
@media (min-width: 992px) {
  #content_layout #content_wrapper .content_body .col-context_menu {
    float: right;
  }
}

div.content_layout {
  background: #f8f9fa;
}

.content_layout_index h2 {
  margin-top: 2em;
  margin-bottom: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
}

#news_index_listing .container-fluid, #news_index_listing .container-sm, #news_index_listing .container-md, #news_index_listing .container-lg, #news_index_listing .container-xl, #brands_index_listing .container-fluid, #brands_index_listing .container-sm, #brands_index_listing .container-md, #brands_index_listing .container-lg, #brands_index_listing .container-xl, #collections_index_listing .container-fluid, #collections_index_listing .container-sm, #collections_index_listing .container-md, #collections_index_listing .container-lg, #collections_index_listing .container-xl {
  position: relative;
}

#collections_index_listing {
  margin-bottom: 40px;
}
#collections_index_listing .collection .view_all {
  padding-top: 15px;
}

#brands_index_listing {
  margin-top: 50px;
  border-top: 1px solid #f8f9fa;
}
#brands_index_listing .brand_listing {
  padding: 35px 10px;
}
#brands_index_listing .brand_listing .brand img {
  width: auto;
  display: inline-block;
}

#product_listing .col, #similar_products .col {
  padding-right: 5px;
  padding-left: 5px;
}
#product_listing .row, #similar_products .row {
  margin-left: -5px;
  margin-right: -5px;
}

#products_listing .banner {
  padding-bottom: 20px;
}
#products_listing .description {
  margin: 0 100px;
  text-align: justify;
}
@media (max-width: 768px) {
  #products_listing .description {
    margin: 0;
  }
}
@media (max-width: 768px) {
  #products_listing .page_size {
    float: none;
    padding: 0;
    text-align: center;
  }
}
#products_listing .sort_block {
  float: right;
}
@media (max-width: 768px) {
  #products_listing .sort_block {
    display: inline-block;
    float: none;
    width: 60%;
    margin: 0;
    padding: 0;
    text-align: right;
  }
  #products_listing .sort_block .bootstrap-select {
    width: 100%;
  }
}
#products_listing #filter_summary {
  float: left;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 1em;
  margin-bottom: 1em;
}
@media (max-width: 768px) {
  #products_listing #filter_summary {
    margin-top: 1em;
  }
}
#products_listing #filter_summary button {
  margin: 0 0.5em 0.5em 0;
}
#products_listing #filter_summary button:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  content: "\e903";
}
@media (min-width: 768px) {
  #products_listing #filters_column {
    width: 20%;
  }
}
@media (min-width: 768px) {
  #products_listing #products_column {
    width: 80%;
  }
}
@media (max-width: 768px) {
  #products_listing #products_column {
    text-align: center;
  }
}
#products_listing #products_column > h1 {
  margin-top: 0;
  float: left;
}
#products_listing #products_column .product_listing {
  clear: both;
}
#products_listing #products_column #filter_on {
  float: none;
}
@media (max-width: 768px) {
  #products_listing #products_column #filter_on {
    clear: left;
  }
}
@media (max-width: 576px) {
  #products_listing #products_column #filter_on {
    width: 38.5%;
  }
}
@media (min-width: 768px) {
  #products_listing #products_column #filter_on {
    display: none;
  }
}

.product_listing {
  padding-top: 10px;
  margin: 0 -10px;
}
@media (max-width: 479px) {
  .product_listing {
    margin: 0 -5px;
  }
}
.product_listing::after {
  display: block;
  clear: both;
  content: "";
}
.product_listing .product_element {
  float: left;
  padding: 10px;
}
@media (min-width: 576px) {
  .product_listing .product_element {
    width: 33.33%;
  }
}
@media (min-width: 768px) {
  .product_listing .product_element {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .product_listing .product_element {
    width: 20%;
  }
}
@media (max-width: 479px) {
  .product_listing .product_element {
    padding: 5px;
    width: 50%;
  }
}
.product_listing .product_element:hover {
  text-decoration: none;
  border-color: #cbd3da;
}
.product_listing .product_element > a {
  display: block;
  position: relative;
  color: #000;
  padding: 0;
  border: none;
  border-radius: 2px;
  background: #fff;
  text-decoration: none;
}
.product_listing .product_element > a .title {
  display: block;
}
.product_listing .product_element > a .price_info {
  display: block;
}
.product_listing .product_element > a .tags {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 10px;
}
.product_listing .product_element > a .tags .tag {
  display: block;
  float: left;
  clear: left;
  padding: 0 10px;
  text-align: left;
  margin-bottom: 10px;
  color: #fff;
}
.product_listing .product_element > a .tags .tag:not(.tag-image) {
  background: #d3408f;
}
.product_listing .product_element > a .img-wrapper {
  display: block;
  text-align: center;
  padding: 10px;
}
.product_listing .product_element > a .img-wrapper img {
  transition: transform 0.5s ease;
  display: inline;
}
.product_listing .product_element > a .img-wrapper.placeholder-wrapper {
  padding: 100% 0 0;
}
.product_listing .product_element > a .title {
  height: 90px;
  text-align: center;
  margin: 15px 0;
  line-height: 20px;
}
.product_listing .product_element > a .title span {
  display: block;
}
.product_listing .product_element > a .title span.brand_name {
  font-size: 13px;
}
.product_listing .product_element > a .title span.product_name {
  font-weight: 400;
  font-size: 15px;
}
.product_listing .product_element > a .title span.modification_name {
  font-size: 15px;
  color: #6c757d;
}
.product_listing .product_element > a .title span.item_name {
  font-size: 15px;
  color: #6c757d;
}
.product_listing .product_element > a .price_info {
  text-align: center;
  line-height: 1.5em;
  height: 1.5em;
}
.product_listing .product_element > a .price_info > span {
  display: block;
}
.product_listing .product_element > a .price_info .price {
  font-weight: 400;
  font-size: 18px;
  color: #343a40;
}
.product_listing .product_element > a .price_info .price.discount {
  color: #fa0f3d;
}
.product_listing .product_element > a .price_info .old_price {
  font-size: 15px;
  text-decoration: line-through;
  color: #6c757d;
}
.product_listing .product_element > a .title, .product_listing .product_element > a .price_info {
  margin: 15px 0;
}
.product_listing .product_element > a:hover .img-wrapper:after {
  opacity: 0.1;
}
.product_listing .product_element > a:hover .img-wrapper img {
  transform: scale(1.1);
}

#filter {
  position: relative;
}
@media (max-width: 768px) {
  #filter.is_shown {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
    background: #fff;
    margin: 0;
    padding: 20px;
  }
}
#filter.is_shown #filter_block {
  display: block;
}
@media (max-width: 768px) {
  #filter.is_shown #filter_block {
    position: absolute;
    top: 70px;
    bottom: 70px;
    left: 10px;
    right: 10px;
    margin: 0;
    border-width: 1px 0 0 0;
    overflow: scroll;
  }
}
@media (max-width: 768px) {
  #filter.is_shown #filters_popup_header {
    display: block;
  }
  #filter.is_shown #filters_popup_header .h1 {
    margin: 0;
  }
  #filter.is_shown #filters_popup_header .icon {
    font-size: 24px;
  }
  #filter.is_shown #filters_popup_footer {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}
#filter .hidden-filter {
  display: none;
}
#filter #filters_popup_header, #filter #filters_popup_footer {
  display: none;
}
#filter #filter_block {
  position: relative;
}
@media (min-width: 768px) {
  #filter #filter_block {
    display: block;
  }
}
@media (max-width: 768px) {
  #filter #filter_block #filter_price > div {
    padding: 15px 30px;
    border-bottom: 1px solid #dee2e6;
    background: #f8f9fa;
  }
}
#filter #filter_block #filter_price #price_slider {
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  height: 2px;
  border: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: #dee2e6;
}
#filter #filter_block #filter_price #price_slider .ui-slider-range {
  top: 0;
}
#filter #filter_block #filter_price #price_slider .ui-slider-handle {
  background: #d3408f url("/public/images/frontend/slide_handle.png") center center no-repeat;
  border: none;
  width: 16px;
  height: 16px;
  margin-top: -3px;
  margin-left: -8px;
}
@media (max-width: 768px) {
  #filter #filter_block #filter_price #price_slider .ui-slider-handle {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -15px;
    border-radius: 15px !important;
  }
}
#filter #filter_block #filter_price #price_slider .ui-widget-header {
  background: none;
  background-color: #d3408f !important;
}
#filter #filter_block #filter_price #price_slider .ui-corner-all {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
#filter #filter_block #filter_price #price_slider_info {
  margin-top: 10px;
}
@media (max-width: 768px) {
  #filter #filter_block #filter_price #price_slider_info {
    margin-top: 20px;
  }
}
#filter #filter_block #filter_price #price_slider_info .val1 {
  float: left;
}
#filter #filter_block #filter_price #price_slider_info .val2 {
  float: right;
}
@media (min-width: 768px) {
  #filter #filter_block .filter-group {
    margin-bottom: 3em;
  }
}
#filter #filter_block .filter-group .title {
  text-transform: uppercase;
}
@media (min-width: 768px) {
  #filter #filter_block .filter-group .title {
    border-bottom: none;
    padding-bottom: 0;
    margin: 0 0 0.75em;
    font-size: 18px;
    font-weight: 500;
  }
}
#filter #filter_block .footer a {
  border: none;
  border-radius: 0;
}
@media (min-width: 768px) {
  #filter #filter_block .footer {
    border-top: 1px solid #e9ecef;
  }
}
#filter a {
  color: #343a40;
}

#products_detailed .summary_wrp {
  float: right;
}
#products_detailed .add2cart_wrp {
  float: right;
  clear: right;
}
#products_detailed .add2cart_wrp #products_add2cart .modifications .modification_selector {
  margin: 0 -5px;
}
#products_detailed .add2cart_wrp #products_add2cart .modifications .modification_selector::after {
  display: block;
  clear: both;
  content: "";
}
#products_detailed .add2cart_wrp #products_add2cart .modifications .modification_selector .modification {
  border: 1px solid #e9ecef;
  margin: 5px;
  float: left;
  position: relative;
  cursor: pointer;
  width: 75px;
  height: 75px;
}
#products_detailed .add2cart_wrp #products_add2cart .modifications .modification_selector .modification:hover::after, #products_detailed .add2cart_wrp #products_add2cart .modifications .modification_selector .modification.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #d3408f;
}
#products_detailed .add2cart_wrp #products_add2cart .modifications .modification_selector .modification span {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
#products_detailed .add2cart_wrp #products_add2cart .items .descriptive_filters:first-child {
  margin-top: -20px;
}
#products_detailed .add2cart_wrp #products_add2cart .modifications, #products_detailed .add2cart_wrp #products_add2cart .items, #products_detailed .add2cart_wrp #products_add2cart .quantity, #products_detailed .add2cart_wrp #products_add2cart .price_info {
  margin-bottom: 20px;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info .price {
  float: left;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info .price .current_price {
  font-size: 32px;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info .price .current_price.no-quantity {
  font-size: 15px;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info .price.has_discount .current_price {
  color: #fa0f3d;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info .price .old_price {
  text-decoration: line-through;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info .add_to_wishlist {
  position: relative;
  float: right;
  font-size: 30px;
  line-height: 46px;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info .add_to_wishlist .checked {
  top: 0;
  position: absolute;
  left: 0;
  transform: scale(0);
  transition: 0.5s;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info .add_to_wishlist.active .checked {
  transform: scale(1);
  transition: 0.5s;
}
#products_detailed .add2cart_wrp #products_add2cart .price_info #add2cart_button {
  float: right;
}
#products_detailed .photos_wrp {
  float: left;
}
#products_detailed .photos_wrp #products_photos {
  background-color: white;
  position: relative;
  padding: 15px;
  margin: 0;
}
#products_detailed .photos_wrp #products_photos::after {
  display: block;
  clear: both;
  content: "";
}
#products_detailed .photos_wrp #products_photos .main_photo {
  width: 100%;
  cursor: zoom-in;
  text-align: center;
  float: right;
}
#products_detailed .photos_wrp #products_photos .main_photo.full_width {
  width: 100%;
}
#products_detailed .photos_wrp #products_photos .main_photo picture img {
  display: none;
}
#products_detailed .photos_wrp #products_photos .main_photo picture.active img {
  display: inline-block;
}
#products_detailed .photos_wrp #products_photos .thumbs {
  width: 0;
  position: relative;
  z-index: 2;
  overflow: visible;
  float: left;
}
#products_detailed .photos_wrp #products_photos .thumbs .thumbs_inner {
  width: 75px;
  margin-left: 1px;
}
@media (max-width: 576px) {
  #products_detailed .photos_wrp #products_photos .thumbs .thumbs_inner {
    width: 50px;
  }
}
#products_detailed .photos_wrp #products_photos .thumbs picture img {
  width: 100%;
  cursor: pointer;
  margin-bottom: 5px;
  border: 1px solid #dee2e6;
}
#products_detailed .photos_wrp #products_photos .thumbs picture:hover img, #products_detailed .photos_wrp #products_photos .thumbs picture.active img {
  border: 1px solid #d3408f;
}
#products_detailed .photos_wrp #products_photos .zoom {
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  display: none;
  overflow: hidden;
  cursor: zoom-out;
  z-index: 1;
}
@media (max-width: 576px) {
  #products_detailed .photos_wrp #products_photos .zoom {
    position: fixed;
    bottom: 0;
    z-index: 101;
    left: 5%;
    top: 15%;
    height: 80%;
    width: 90%;
    border: 1px solid black;
  }
  #products_detailed .photos_wrp #products_photos .zoom .close {
    font-size: 30px;
    opacity: 1;
  }
}
#products_detailed .photos_wrp #products_photos .zoom .wrapper {
  position: relative;
}
#products_detailed .photos_wrp #products_photos .zoom .wrapper img {
  position: absolute;
}
#products_detailed .product_detailed_description_wrp {
  padding: 20px 0;
}
#products_detailed .tags {
  left: 0;
  top: 10px;
}
#products_detailed .tags .tag {
  padding: 0 10px;
  text-align: left;
  margin-bottom: 10px;
  color: #fff;
  margin-right: 10px;
}
#products_detailed .tags .tag:not(.tag-image) {
  background: #d3408f;
}

.news_list {
  margin: 5px -5px;
}
.news_list::after {
  display: block;
  clear: both;
  content: "";
}
.news_list .news_element {
  float: left;
  display: block;
  padding: 5px;
  border: 1px solid rgb(172, 172, 172);
  height: 100%;
}
.news_list .news_element a {
  display: block;
}
.news_list .news_element a:hover {
  text-decoration: none;
}
.news_list .news_element a img {
  width: 100%;
}
.news_list .news_element span {
  display: block;
}
.news_list .news_element span.date {
  position: absolute;
  float: left;
  font-size: 14px;
  color: rgb(144, 144, 144);
}
.news_list .news_element span.category {
  float: left;
  margin: 15px 0;
}
.news_list .news_element span.name {
  clear: both;
  font-size: 16px;
  font-weight: bold;
  color: rgb(5, 122, 57);
  padding-top: 5px;
}
.news_list .news_element span.description {
  position: relative;
  float: left;
  font-size: 14px;
  color: #000;
  margin-top: 20px;
  height: 160px;
  overflow: hidden;
}

#news_detailed .info {
  margin: 15px 0;
}
#news_detailed .info::after {
  display: block;
  clear: both;
  content: "";
}
#news_detailed .info span {
  display: block;
  float: left;
  margin-right: 15px;
}
#news_detailed .main_photo {
  margin: 15px 0;
  text-align: center;
  background: #dee2e6;
}
#news_detailed .main_photo img {
  max-width: 100%;
  max-height: 710px;
}
#news_detailed .main_photo .title {
  display: block;
  font-size: 12px;
  padding: 0.5em;
}

#news_detailed .category, .news_list .category {
  background-color: #d3408f;
  padding: 0 10px;
  color: #fff;
}

#feedback_page h1 {
  text-align: center;
}
#feedback_page .group_title, #feedback_page .q, #feedback_page .submit {
  padding-left: 30px;
}
@media (max-width: 768px) {
  #feedback_page .group_title, #feedback_page .q, #feedback_page .submit {
    padding-left: 10px;
    min-width: 300px;
  }
}
#feedback_page .submit {
  padding-top: 30px;
}
#feedback_page .feedback_group .group_title h2 {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 35px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 0;
}
#feedback_page .feedback_group .group_title h2:before {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d3408f;
}
#feedback_page .feedback_group > .table-responsive {
  margin-bottom: 82px;
}
#feedback_page .feedback_group > .table-responsive > table {
  font-weight: 500;
  border: none;
}
#feedback_page .feedback_group > .table-responsive > table tr.odd td {
  background-color: #f8f9fa;
}
#feedback_page .feedback_group > .table-responsive > table tr td {
  border-bottom: 1px solid #e9ecef;
  border-top: none;
}
#feedback_page .feedback_group > .table-responsive > table tr td.answer {
  width: 110px;
  text-align: center;
  vertical-align: middle;
}
#feedback_page .feedback_group > .table-responsive > table thead .answer {
  font-size: 14px;
}
#feedback_page .feedback_group > .table-responsive > table tbody tr td.q {
  font-size: 16px;
}
#feedback_page .product_element .content {
  padding-bottom: 0;
}
#feedback_page .product_element .content .product_title {
  min-height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

/** 
atsiliepimo palikimo puslapyje
produktu blokai 
**/
.add_review .form-check label::before {
  top: 0;
}
.add_review div.review_part {
  margin: 25px 0;
}
.add_review div.stars_wrp label {
  text-align: left;
  display: block;
  padding-top: 2px;
  float: left;
  line-height: 16px;
  width: 150px;
  margin-right: 5px;
  font-size: 13px;
  margin-left: 5px;
}
.add_review div.select_wrp {
  float: left;
  margin-left: 20px;
}
.add_review div.select_wrp.first {
  margin-left: 0;
}
.add_review div.select_wrp label {
  display: block;
  font-size: 11px;
  margin-top: 4px;
  color: #424242;
}
.add_review .feedback {
  font-size: 16px;
}
.add_review .descr {
  color: #adb5bd;
  font-size: 14px;
}
.add_review .product_feedback_wrp {
  border-bottom: 1px solid #e9ecef;
}
.add_review .product_feedback_wrp .product_element {
  font-weight: 300;
}
.add_review .product_feedback_wrp .product_element a, .add_review .product_feedback_wrp .product_element a:hover {
  text-decoration: none;
  color: inherit;
}
.add_review .product_feedback_wrp .product_element .product_title {
  text-align: center;
  font-size: 18px;
}
.add_review .product_feedback_wrp .product_element .product_title small {
  font-size: 16px;
}
@media (min-width: 768px) {
  .add_review .product_feedback_wrp .product_wrp, .add_review .product_feedback_wrp .feedback_wrp {
    float: right;
  }
}
.add_review .product_feedback_wrp .review_wrp textarea {
  width: 100%;
  height: 138px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 20px;
}
.add_review .product_feedback_wrp .review_wrp input.text {
  width: 294px;
}
.add_review .product_feedback_wrp .rating {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  margin-bottom: 12px;
}
.add_review .product_feedback_wrp .rating::after {
  display: block;
  clear: both;
  content: "";
}
.add_review .product_feedback_wrp .rating .stars {
  display: block;
  float: left;
  width: 110px;
  height: 16px;
  position: relative;
  background: url("/public/images/frontend/star.png") bottom;
}
.add_review .product_feedback_wrp .rating span.title {
  font-size: 16px;
  font-weight: 600;
}
.add_review .product_feedback_wrp .rating span .hover {
  display: block;
  width: 0px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 0;
  text-indent: -9999px;
  background: url("/public/images/frontend/star.png") top left;
}
.add_review .product_feedback_wrp .rating span.stars1 {
  width: 22px;
}
.add_review .product_feedback_wrp .rating span.stars2 {
  width: 44px;
}
.add_review .product_feedback_wrp .rating span.stars3 {
  width: 66px;
}
.add_review .product_feedback_wrp .rating span.stars4 {
  width: 88px;
}
.add_review .product_feedback_wrp .rating span.stars5 {
  width: 110px;
}
.add_review .product_feedback_wrp .rating span.stars0 {
  width: 0px;
}
.add_review .product_feedback_wrp .rating a:hover {
  background: url("/public/images/frontend/star.png") top left;
}
.add_review .product_feedback_wrp .rating a {
  display: block;
  width: 90px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 0;
  text-indent: -9999px;
  overflow: hidden;
}
.add_review .product_feedback_wrp .rating a.rate1 {
  width: 22px;
  z-index: 5;
}
.add_review .product_feedback_wrp .rating a.rate2 {
  width: 44px;
  z-index: 4;
}
.add_review .product_feedback_wrp .rating a.rate3 {
  width: 66px;
  z-index: 3;
}
.add_review .product_feedback_wrp .rating a.rate4 {
  width: 88px;
  z-index: 2;
}
.add_review .product_feedback_wrp .rating a.rate5 {
  width: 110px;
  z-index: 1;
}

#product_reviews .star {
  height: 29px;
  width: 35px;
  background: url("/public/images/frontend/star_big.png") bottom no-repeat;
  display: block;
}
#product_reviews .raiting {
  margin-bottom: 10px;
}
#product_reviews .raiting .stars {
  display: block;
  float: left;
  width: 110px;
  height: 16px;
  position: relative;
  background: url("/public/images/frontend/star.png") bottom;
}
#product_reviews .raiting .stars span {
  display: block;
  width: 0px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 0;
  text-indent: -9999px;
  background: url("/public/images/frontend/star.png") top left;
}
#product_reviews .raiting.lg .stars {
  width: 175px;
  height: 29px;
  background: url("/public/images/frontend/star_big.png") bottom;
}
#product_reviews .raiting.lg .stars span {
  background: url("/public/images/frontend/star_big.png") top left;
  height: 29px;
}
#product_reviews .summary_block.content {
  padding-top: 20px;
  padding-bottom: 20px;
}
#product_reviews .reviews {
  /*padding: 0 40px;*/
}
#product_reviews .reviews.content {
  padding-top: 0;
  padding-bottom: 0;
}
#product_reviews .reviews .review {
  position: relative;
  padding: 35px 0;
}
#product_reviews .reviews .review .date {
  position: absolute;
  top: 35px;
  right: 15px;
}
@media (max-width: 768px) {
  #product_reviews .reviews .review .date {
    top: 15px;
  }
}
#product_reviews .reviews .review .name {
  font-size: 18px;
  line-height: 24px;
}
#product_reviews .reviews .review .info_text {
  line-height: 24px;
  color: #6c757d;
}
#product_reviews .reviews .review .info_text img {
  vertical-align: middle;
  margin-right: 5px;
}
#product_reviews .reviews .review p {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  #product_reviews .reviews .review {
    padding: 15px 0;
  }
}
#product_reviews .btn-wrp {
  text-align: center;
}
#product_reviews .btn-wrp .btn {
  margin: 0 auto;
}

.banners_hero .container-fluid, .banners_hero .container-sm, .banners_hero .container-md, .banners_hero .container-lg, .banners_hero .container-xl {
  max-width: 1380px;
}
.banners_hero .hero_desktop_slider .owl-carousel, .banners_hero .hero_mobile_slider .owl-carousel {
  padding: 0px;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-prev, .banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-next, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-prev, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  margin-top: 0px;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-prev .icon, .banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-next .icon, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-prev .icon, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-next .icon {
  color: #ffffff;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-prev .icon:before, .banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-next .icon:before, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-prev .icon:before, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-next .icon:before {
  font-size: 15px;
  font-weight: 800;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-prev, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-prev {
  left: 15px;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-next, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-next {
  right: 15px;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-dots, .banners_hero .hero_mobile_slider .owl-carousel .owl-dots {
  display: flex;
  gap: 1px;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-dots .owl-dot, .banners_hero .hero_mobile_slider .owl-carousel .owl-dots .owl-dot {
  flex: 1;
  height: 6px;
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.3s;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-dots .owl-dot span, .banners_hero .hero_mobile_slider .owl-carousel .owl-dots .owl-dot span {
  display: none;
}
.banners_hero .hero_desktop_slider .owl-carousel .owl-dots .owl-dot.active, .banners_hero .hero_desktop_slider .owl-carousel .owl-dots .owl-dot:hover, .banners_hero .hero_mobile_slider .owl-carousel .owl-dots .owl-dot.active, .banners_hero .hero_mobile_slider .owl-carousel .owl-dots .owl-dot:hover {
  background: #EDEDED;
}
.banners_hero .hero_desktop_slider .item .banner-fill-link, .banners_hero .hero_mobile_slider .item .banner-fill-link {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.banners_hero .hero_desktop_slider .item .banner_img, .banners_hero .hero_mobile_slider .item .banner_img {
  aspect-ratio: 360/243;
  background-size: cover;
  background-position: center;
}
.banners_hero .hero_desktop_slider .item .title_wrp, .banners_hero .hero_mobile_slider .item .title_wrp {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.banners_hero .hero_desktop_slider .item .title_wrp a, .banners_hero .hero_mobile_slider .item .title_wrp a {
  padding: 20px 25px;
}
.banners_hero .hero_desktop_slider .item .title_wrp a:hover, .banners_hero .hero_mobile_slider .item .title_wrp a:hover {
  text-decoration: none;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter {
  display: flex;
  height: 100%;
  align-items: center;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter.ha-left, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter.ha-left {
  justify-content: flex-start;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter.ha-center, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter.ha-center {
  justify-content: center;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter.ha-right, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter.ha-right {
  justify-content: flex-end;
  text-align: right;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter.ha-right .banner_content h1:after, .banners_hero .hero_desktop_slider .item .title_wrp .vcenter.ha-right .banner_content .title:after, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter.ha-right .banner_content h1:after, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter.ha-right .banner_content .title:after {
  margin-left: auto;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter.va-top, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter.va-top {
  align-items: flex-start;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter.va-middle, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter.va-middle {
  align-items: center;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter.va-bottom, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter.va-bottom {
  align-items: flex-end;
}
.banners_hero .hero_desktop_slider .item .title_wrp .vcenter:before, .banners_hero .hero_mobile_slider .item .title_wrp .vcenter:before {
  display: none;
}
.banners_hero .hero_desktop_slider .item .title_wrp .banner_content, .banners_hero .hero_mobile_slider .item .title_wrp .banner_content {
  max-width: 400px;
  white-space: initial;
}
.banners_hero .hero_desktop_slider .item .title_wrp .banner_content h1, .banners_hero .hero_desktop_slider .item .title_wrp .banner_content .title, .banners_hero .hero_mobile_slider .item .title_wrp .banner_content h1, .banners_hero .hero_mobile_slider .item .title_wrp .banner_content .title {
  margin: 10px 0px 10px 0px;
  font-size: 20px;
  line-height: 24px;
}
.banners_hero .hero_desktop_slider .item .title_wrp .banner_content .desc, .banners_hero .hero_mobile_slider .item .title_wrp .banner_content .desc {
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 13px;
  line-height: 22px;
}
@media (max-width: 767.98px) {
  .banners_hero .hero_desktop_slider, .banners_hero .hero_mobile_slider {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-prev .icon:before, .banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-next .icon:before, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-prev .icon:before, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-next .icon:before {
    font-size: 25px;
  }
  .banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-prev, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-prev {
    left: 50px;
  }
  .banners_hero .hero_desktop_slider .owl-carousel .owl-nav button.owl-next, .banners_hero .hero_mobile_slider .owl-carousel .owl-nav button.owl-next {
    right: 50px;
  }
  .banners_hero .hero_desktop_slider .owl-carousel .owl-dots, .banners_hero .hero_mobile_slider .owl-carousel .owl-dots {
    gap: 3px;
  }
  .banners_hero .hero_desktop_slider .owl-carousel .owl-dots .owl-dot, .banners_hero .hero_mobile_slider .owl-carousel .owl-dots .owl-dot {
    height: 13px;
  }
  .banners_hero .hero_desktop_slider .item .banner_img, .banners_hero .hero_mobile_slider .item .banner_img {
    aspect-ratio: 1380/713;
  }
  .banners_hero .hero_desktop_slider .item .title_wrp a, .banners_hero .hero_mobile_slider .item .title_wrp a {
    padding: 87px 100px;
  }
  .banners_hero .hero_desktop_slider .item .title_wrp .banner_content h1, .banners_hero .hero_desktop_slider .item .title_wrp .banner_content .title, .banners_hero .hero_mobile_slider .item .title_wrp .banner_content h1, .banners_hero .hero_mobile_slider .item .title_wrp .banner_content .title {
    margin: 1em 0px 34px 0px;
    font-size: 38px;
    line-height: 38px;
  }
  .banners_hero .hero_desktop_slider .item .title_wrp .banner_content .desc, .banners_hero .hero_mobile_slider .item .title_wrp .banner_content .desc {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 16px;
  }
}

#banners_small .container-fluid, #banners_small .container-sm, #banners_small .container-md, #banners_small .container-lg, #banners_small .container-xl {
  max-width: 1300px;
  padding: 0 20px;
}
#banners_small .container-fluid #banners-small-slider, #banners_small .container-sm #banners-small-slider, #banners_small .container-md #banners-small-slider, #banners_small .container-lg #banners-small-slider, #banners_small .container-xl #banners-small-slider {
  padding: 0;
}
#banners_small .container-fluid #banners-small-slider div.banner, #banners_small .container-sm #banners-small-slider div.banner, #banners_small .container-md #banners-small-slider div.banner, #banners_small .container-lg #banners-small-slider div.banner, #banners_small .container-xl #banners-small-slider div.banner {
  margin: 40px 0;
  padding: 0 20px;
  text-align: center;
}
#banners_small .container-fluid #banners-small-slider div.banner .banner-image, #banners_small .container-sm #banners-small-slider div.banner .banner-image, #banners_small .container-md #banners-small-slider div.banner .banner-image, #banners_small .container-lg #banners-small-slider div.banner .banner-image, #banners_small .container-xl #banners-small-slider div.banner .banner-image {
  overflow: hidden;
}
#banners_small .container-fluid #banners-small-slider div.banner .zoom, #banners_small .container-sm #banners-small-slider div.banner .zoom, #banners_small .container-md #banners-small-slider div.banner .zoom, #banners_small .container-lg #banners-small-slider div.banner .zoom, #banners_small .container-xl #banners-small-slider div.banner .zoom {
  position: relative;
  padding: 0;
  overflow: hidden;
}
#banners_small .container-fluid #banners-small-slider div.banner .zoom img, #banners_small .container-sm #banners-small-slider div.banner .zoom img, #banners_small .container-md #banners-small-slider div.banner .zoom img, #banners_small .container-lg #banners-small-slider div.banner .zoom img, #banners_small .container-xl #banners-small-slider div.banner .zoom img {
  transition: transform 0.5s ease;
}
#banners_small .container-fluid #banners-small-slider div.banner .zoom:hover img, #banners_small .container-sm #banners-small-slider div.banner .zoom:hover img, #banners_small .container-md #banners-small-slider div.banner .zoom:hover img, #banners_small .container-lg #banners-small-slider div.banner .zoom:hover img, #banners_small .container-xl #banners-small-slider div.banner .zoom:hover img {
  transform: scale(1.1);
}
#banners_small .container-fluid #banners-small-slider div.banner a, #banners_small .container-sm #banners-small-slider div.banner a, #banners_small .container-md #banners-small-slider div.banner a, #banners_small .container-lg #banners-small-slider div.banner a, #banners_small .container-xl #banners-small-slider div.banner a {
  color: #000;
}
#banners_small .container-fluid #banners-small-slider div.banner a, #banners_small .container-sm #banners-small-slider div.banner a, #banners_small .container-md #banners-small-slider div.banner a, #banners_small .container-lg #banners-small-slider div.banner a, #banners_small .container-xl #banners-small-slider div.banner a, #banners_small .container-fluid #banners-small-slider div.banner a:hover {
  text-decoration: none;
}
#banners_small .container-fluid #banners-small-slider div.banner .banner-title, #banners_small .container-sm #banners-small-slider div.banner .banner-title, #banners_small .container-md #banners-small-slider div.banner .banner-title, #banners_small .container-lg #banners-small-slider div.banner .banner-title, #banners_small .container-xl #banners-small-slider div.banner .banner-title {
  padding: 25px 5px 5px;
  background: #fff;
}
#banners_small .container-fluid #banners-small-slider div.banner .banner-title p, #banners_small .container-sm #banners-small-slider div.banner .banner-title p, #banners_small .container-md #banners-small-slider div.banner .banner-title p, #banners_small .container-lg #banners-small-slider div.banner .banner-title p, #banners_small .container-xl #banners-small-slider div.banner .banner-title p {
  padding: 5px 0;
  margin: 0;
}
#banners_small .container-fluid #banners-small-slider div.banner .banner-title .title, #banners_small .container-sm #banners-small-slider div.banner .banner-title .title, #banners_small .container-md #banners-small-slider div.banner .banner-title .title, #banners_small .container-lg #banners-small-slider div.banner .banner-title .title, #banners_small .container-xl #banners-small-slider div.banner .banner-title .title {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
}
#banners_small .container-fluid #banners-small-slider div.banner .banner-title .desc, #banners_small .container-sm #banners-small-slider div.banner .banner-title .desc, #banners_small .container-md #banners-small-slider div.banner .banner-title .desc, #banners_small .container-lg #banners-small-slider div.banner .banner-title .desc, #banners_small .container-xl #banners-small-slider div.banner .banner-title .desc {
  font-size: 20px;
  text-transform: uppercase;
}
#banners_small .container-fluid #banners-small-slider div.banner .banner-title .btn, #banners_small .container-sm #banners-small-slider div.banner .banner-title .btn, #banners_small .container-md #banners-small-slider div.banner .banner-title .btn, #banners_small .container-lg #banners-small-slider div.banner .banner-title .btn, #banners_small .container-xl #banners-small-slider div.banner .banner-title .btn {
  margin-top: 9px;
}
#banners_small .container-fluid #banners-small-slider div.banner:hover .btn, #banners_small .container-sm #banners-small-slider div.banner:hover .btn, #banners_small .container-md #banners-small-slider div.banner:hover .btn, #banners_small .container-lg #banners-small-slider div.banner:hover .btn, #banners_small .container-xl #banners-small-slider div.banner:hover .btn {
  background: #000;
  color: #fff;
}
@media (min-width: 576px) {
  #banners_small .container-fluid #banners-small-slider:not(.owl-carousel) div.banner, #banners_small .container-sm #banners-small-slider:not(.owl-carousel) div.banner, #banners_small .container-md #banners-small-slider:not(.owl-carousel) div.banner, #banners_small .container-lg #banners-small-slider:not(.owl-carousel) div.banner, #banners_small .container-xl #banners-small-slider:not(.owl-carousel) div.banner {
    float: left;
    width: 33.33%;
  }
}

#wishlist_list table {
  width: 100%;
  border-collapse: collapse;
}
#wishlist_list table td {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding: 10px;
}
#wishlist_list table td.thumb {
  width: 140px;
}
#wishlist_list table td.actions {
  text-align: right;
}
@media (max-width: 768px) {
  #wishlist_list table td.thumb {
    width: 50%;
  }
  #wishlist_list table td.name {
    width: 50%;
  }
  #wishlist_list table td.price {
    width: 30%;
  }
  #wishlist_list table td.actions {
    width: 70%;
    text-align: right;
  }
}

#departments_listing {
  margin-bottom: 25px;
}
#departments_listing .filters ul {
  text-align: center;
}
#departments_listing .filters ul li {
  font-size: 16px;
  color: #000;
  padding: 25px 20px 20px 0px;
  display: inline-block;
  position: relative;
}
#departments_listing .filters ul li:before {
  content: none;
}
#departments_listing .element {
  margin-top: 20px;
}
#departments_listing .element span {
  display: block;
}
#departments_listing .element .name {
  font-size: 15px;
  color: #d3408f;
  margin: 20px 0 5px;
}
#departments_listing .element .placeholder {
  width: 100%;
  display: block;
}
@media (min-width: 576px) {
  #departments_listing .element .placeholder {
    height: 194px;
  }
}
#departments_listing .elements_listing {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#cart_items table {
  border-bottom: 3px solid #d3408f;
  width: 100%;
}
#cart_items table td, #cart_items table th {
  text-align: center;
}
#cart_items table td.title, #cart_items table th.title {
  text-align: left;
}
#orders_detailed #cart_items table td.item_price, #orders_detailed #cart_items table td.amount, #orders_detailed #cart_items table td.sum, #orders_detailed #cart_items table th.item_price, #orders_detailed #cart_items table th.amount, #orders_detailed #cart_items table th.sum {
  text-align: right;
}
#orders_detailed #cart_items table td.sum, #orders_detailed #cart_items table th.sum {
  padding-right: 0;
}
#cart_items table thead {
  border-bottom: 3px solid #d3408f;
}
#cart_items table thead th {
  text-align: center;
  padding: 10px 20px;
}
#cart_items table tr.item {
  background: white;
}
#cart_items table tr.item td {
  border-bottom: 1px solid #e9ecef;
  padding: 10px 20px;
  vertical-align: middle;
}
#cart_items table tr.item td.photo {
  width: 111px;
  padding: 10px 0;
}
#cart_items table tr.item td.title span.product_name {
  text-transform: uppercase;
  font-size: 14px;
}
#cart_items table tr.item td.title a.cart_remove {
  display: inline-block;
  margin-top: 1em;
  font-size: 11px;
}
#cart_items table tr.item td.amount {
  width: 160px;
}
#cart_items table tr.item td.sum, #cart_items table tr.item td.item_price {
  min-width: 100px;
}
#cart_items table tr.item td.sum .old_price, #cart_items table tr.item td.item_price .old_price {
  color: #6c757d;
  display: block;
  text-decoration: line-through;
  font-size: 0.9em;
}
#cart_items table tr.item td.cart_remove {
  padding-left: 0;
  width: 30px;
}
#cart_items table tr.item .price_block .price {
  font-weight: 700;
  color: #343a40;
}
#cart_items table tr.item .price_block .old_price {
  color: #212529;
  text-decoration: line-through;
}
#cart_items table tr.item .price_block.has_old_price .price {
  margin-bottom: 0;
}

#steps {
  padding: 20px 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  font-weight: 500;
}
#steps div.step {
  text-align: center;
}
#steps div.step span.round {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #6c757d;
  background-color: #dee2e6;
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-right: 5px;
}
@media (max-width: 768px) {
  #steps div.step span.text {
    display: none;
  }
  #steps div.step:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: -20px;
    width: 40px;
    height: 6px;
    margin-top: -3px;
    background: transparent url("/public/images/frontend/cart_step.png") no-repeat center center;
  }
  #steps div.step:last-child:after {
    display: none;
  }
}
@media (min-width: 768px) {
  #steps div.step:first-child {
    text-align: left;
  }
  #steps div.step:last-child {
    text-align: right;
  }
}
#steps div.step a {
  color: #495057;
  text-decoration: none;
}
#steps div.step a:hover {
  color: #212529;
}
#steps div.step a span.round {
  color: #fff;
  background-color: #6c757d;
}
#steps div.step.active {
  font-weight: bold;
}
#steps div.step.active span.round {
  background-color: #d3408f;
}

#cart_detailed {
  margin-bottom: 20px;
}
body.screen-xs #cart_detailed #back_to_shop_col {
  margin-top: 15px;
}
body.screen-xs #cart_detailed #back_to_shop_col a {
  display: block;
}
#cart_detailed .form-wrapper {
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  #cart_detailed #discount_form h3 {
    margin-top: -4px;
    font-size: 16px;
    font-family: "Calibre", sans-serif;
    font-weight: 700;
    color: #495057;
  }
  #cart_detailed #info_block {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;
    max-width: 1170px;
    margin: 0 auto;
  }
  #cart_detailed #info_block .discount_code {
    margin-bottom: 15px;
  }
}

#content_layout_cart {
  min-height: 100%;
  background: #f8f9fa;
}
#content_layout_cart::after {
  display: block;
  clear: both;
  content: "";
}
#content_layout_cart #logo img {
  max-width: none;
  max-height: 50px;
}
#content_layout_cart .container-fluid, #content_layout_cart .container-sm, #content_layout_cart .container-md, #content_layout_cart .container-lg, #content_layout_cart .container-xl {
  max-width: 1010px;
}
#content_layout_cart .content_body {
  margin-bottom: 30px;
}
#content_layout_cart h1, #content_layout_cart h2 {
  text-align: center;
}
#content_layout_cart #header {
  height: 70px;
  background-color: white;
}
#content_layout_cart #header #logo {
  margin-top: 12px;
}
#content_layout_cart #header #logo img {
  max-width: 160px;
}
#content_layout_cart #header .info_block {
  margin-top: 23px;
}

#cart_vouchers {
  margin-top: 20px;
}
#cart_vouchers .list-default {
  margin-bottom: 15px;
}

#cart_summary {
  padding: 20px 0;
  margin-top: 0;
}
#cart_summary .summary {
  padding-bottom: 15px;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 15px;
}
#cart_summary .total * {
  font-size: 16px;
  font-weight: bold;
}

#cart_confirm .rules_block {
  padding-top: 2px;
}
#cart_confirm .rules_block a {
  color: #d3408f;
}
@media (max-width: 768px) {
  #cart_confirm div.button-row {
    padding-top: 60px;
    position: relative;
  }
  #cart_confirm div.button-row div.form-check {
    position: absolute;
    left: 0;
    top: 0;
  }
}
#cart_confirm div.button-row div.form-check {
  margin-right: 20px;
  margin-top: 0;
}

#order_payment_block .payment-option {
  font-weight: 500;
}
#order_payment_block .payment-option.last {
  border-bottom: none;
}
#order_payment_block .payment-option p {
  font-size: 13px;
  margin-bottom: 0;
}
#order_payment_block .payment-option .payment_list .payment_type {
  margin-top: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  position: relative;
  text-align: center;
  padding: 10px;
  border: 1px solid #e9ecef;
}
#order_payment_block .payment-option .payment_list .payment_type .img-responsive {
  display: inline-block;
}
#order_payment_block .payment-option .payment_list .payment_type:hover em, #order_payment_block .payment-option .payment_list .payment_type.active em {
  -webkit-border-radius: 0 0 1px 1px;
  -moz-border-radius: 0 0 1px 1px;
  border-radius: 0 0 1px 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #d3408f;
  z-index: 2;
}

#delivery_type_options .control-label {
  width: 100%;
  text-align: left;
}
#delivery_type_options .control-label .delivery_price {
  color: #6c757d;
  float: right;
}

#connect2bank {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
#connect2bank div {
  position: relative;
  height: 100%;
  width: 100%;
}
#connect2bank div img {
  position: absolute;
  top: 50%;
  left: 50%;
}

#order_information_block {
  background: #fff;
}
#order_information_block::after {
  display: block;
  clear: both;
  content: "";
}
#order_information_block > div {
  margin-bottom: 20px;
}
#order_information_block h4 {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-top: 0;
}
#order_information_block h5 + a.btn {
  margin-top: 10px;
}

#orders_detailed {
  margin-bottom: 30px;
}
#orders_detailed #prepayment_details span {
  font-weight: bold;
}

#customers_authorize {
  max-width: 400px;
  margin: 0 auto;
}
#customers_authorize .group .form-group {
  margin-bottom: 20px;
}
#customers_authorize .remind_password {
  padding-bottom: 20px;
}
#customers_authorize .center-block {
  float: none;
}
#customers_authorize #login_form {
  margin-bottom: 0;
}

#customers_login input[name=email], #customers_register input[name=email] {
  background: #fff;
}

#customers_register .text-left label {
  display: inline;
}

@keyframes working {
  15% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: bottom right;
  }
  30% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
    transform-origin: bottom right;
  }
  35% {
    -webkit-transform: rotate(-23deg);
    transform: rotate(-23deg);
    transform-origin: bottom right;
  }
  38% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
    transform-origin: bottom right;
  }
  80% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
    transform-origin: bottom right;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: bottom right;
  }
}
@keyframes show-more {
  15% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.2) translateY(12px);
    transform: scale(1.2) translateY(12px);
  }
  35% {
    -webkit-transform: scale(1.2) translateY(10px);
    transform: scale(1.2) translateY(10px);
  }
  38% {
    -webkit-transform: scale(1.2) translateY(12px);
    transform: scale(1.2) translateY(12px);
  }
  80% {
    -webkit-transform: scale(1.2) translateY(12px);
    transform: scale(1.2) translateY(12px);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
body {
  background-color: #FCD8C7;
  color: #843B03;
  font-family: "Jost", "sans-serif";
  font-size: 16px;
}
@media (max-width: 991px) {
  body {
    font-size: 18px;
  }
}

button {
  background: none;
  border: none;
  color: #843B03;
}
button:focus {
  outline: none;
}
button::selection {
  background: #000;
  color: #FFF;
}

h2 {
  font-family: "Georgia", "sans-serif";
  font-size: 32px;
  margin-bottom: 35px;
  margin-top: 0;
  line-height: 36px;
}
h2::selection {
  background: #000;
  color: #FFF;
}
@media (max-width: 991px) {
  h2 {
    margin-top: 125px;
  }
}

h3 {
  font-family: "Jost", "sans-serif";
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 991px) {
  h3 {
    font-size: 18px;
  }
}
h3::selection {
  background: #000;
  color: #FFF;
}

.divider {
  border-top: 2px solid #843B03;
  display: block;
  margin-bottom: 105px;
  width: 57px;
  -webkit-transform: translateX(-200px);
  transform: translateX(-200px);
  transition: transform 1.8s ease;
}
@media (max-width: 991px) {
  .divider {
    margin-bottom: 50px;
    transform: none;
  }
}

a {
  color: #843B03;
}
a:hover {
  color: #843B03;
  text-decoration: none;
}
a::selection {
  background: #000;
  color: #FFF;
}

p::selection {
  background: #000;
  color: #FFF;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  margin-top: 50px;
  width: 100%;
  z-index: 2;
}
@media (max-width: 991px) {
  .header {
    display: none;
  }
}

.mobile-header {
  background-color: #FCD8C7;
  box-shadow: 0 30px 10px -8px rgba(247, 200, 178, 0.3);
  padding-bottom: 15px;
  padding-top: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
}
@media (min-width: 992px) {
  .mobile-header {
    display: none;
  }
}
@media (max-width: 991px) {
  .mobile-header img {
    max-width: 215px;
  }
}
.mobile-header .burger {
  padding: 10px;
  position: absolute;
  left: 15px;
  top: 25px;
}
.mobile-header .burger span {
  border-top: 2px solid #843B03;
  display: block;
  transition: transform 0.8s ease-in-out;
  width: 20px;
}
.mobile-header .burger span:not(:first-of-type) {
  margin-top: 6px;
}

.mobile-menu {
  position: fixed;
  top: 90px;
  width: 100%;
  z-index: 2;
  -webkit-transform: translateY(-400px);
  transform: translateY(-400px);
  transition: transform 0.8s ease-in-out;
}
@media (min-width: 992px) {
  .mobile-menu {
    display: none;
  }
}
.mobile-menu .menu-link {
  background-color: #FCD8C7;
  border-bottom: 1px solid #EFC9B8;
  display: block;
  font-size: 22px;
  padding: 15px 25px;
  text-align: left;
  width: 100%;
}
.mobile-menu .menu-link:hover {
  background-color: #EFC9B8;
}

body.open-menu .mobile-header .burger span:first-of-type {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
body.open-menu .mobile-header .burger span:last-of-type {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}
body.open-menu .mobile-menu {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.navigation-block {
  position: fixed;
  bottom: 15vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: bottom 1.8s ease;
}
@media (min-width: 992px) and (max-height: 900px) {
  .navigation-block {
    bottom: 5vh;
  }
}
@media (max-width: 991px) {
  .navigation-block {
    bottom: 5vh;
    z-index: 3;
  }
}
.navigation-block .show-more {
  transition: transform 0.3s ease;
}
@media (max-width: 991px) {
  .navigation-block .show-more {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.navigation-block .show-more:hover {
  -webkit-transform: scale(1.2) translateY(12px);
  transform: scale(1.2) translateY(12px);
}
@media (max-width: 991px) {
  .navigation-block .show-more:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.navigation-block .prev-page {
  display: block;
  margin: 0 auto 80px;
  opacity: 0;
  transition: all 1.5s ease;
}
.navigation-block .prev-page .show-more:hover {
  -webkit-transform: scale(1.2) translateY(-12px);
  transform: scale(1.2) translateY(-12px);
}
.navigation-block p {
  opacity: 0;
  margin-top: 25px;
  transition: all 0.5s ease;
}
@media (max-width: 991px) {
  .navigation-block p {
    opacity: 1;
  }
}

.fp-section {
  opacity: 0;
  transition: all 1.8s ease;
}
.fp-section.active {
  opacity: 1;
}

.fp-tableCell {
  vertical-align: top;
}

.fp-viewing-0 .navigation-block p {
  opacity: 1;
}
@media (min-width: 992px) {
  .fp-viewing-0 .navigation-block .show-more {
    animation: show-more 1.2s linear infinite;
  }
}
.fp-viewing-0 .working-block .hammer {
  animation: working 1.2s linear infinite;
}

[class^=fp-viewing-1] .navigation-block {
  bottom: 5vh;
}

.fp-viewing-2 .about-page .divider {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.fp-viewing-2 .about-page .info-section .info-bg {
  max-height: 915px;
  max-width: 819px;
  width: calc((100vh - 245px) * 819 / 915);
}
.fp-viewing-2 .about-page .menu-about {
  font-weight: 600;
}
.fp-viewing-2 .navigation-block {
  bottom: -40px;
}
.fp-viewing-2 .navigation-block .prev-page {
  opacity: 1;
  -webkit-transform: translateY(calc(-100vh + 350px));
  transform: translateY(calc(-100vh + 350px));
}
.fp-viewing-2 .navigation-block .prev-page {
  opacity: 1;
}

.fp-viewing-3 .brands-page .divider {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.fp-viewing-3 .brands-page .menu-brands {
  font-weight: 600;
}
.fp-viewing-3 .navigation-block {
  bottom: -40px;
}
.fp-viewing-3 .navigation-block .prev-page {
  -webkit-transform: translateY(calc(-100vh + 350px));
  transform: translateY(calc(-100vh + 350px));
}
.fp-viewing-3 .navigation-block button {
  opacity: 0;
}
.fp-viewing-3 .navigation-block button.show {
  opacity: 1;
}
.fp-viewing-3 #brands_listing .row {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.fp-viewing-4 .contacts-page .divider {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.fp-viewing-4 .contacts-page .info-section .info-bg {
  max-height: 876px;
  max-width: 664px;
  width: calc((100vh - 245px) * 664 / 876);
}
.fp-viewing-4 .contacts-page .menu-contacts {
  font-weight: 600;
}
.fp-viewing-4 .navigation-block {
  bottom: -40px;
}
.fp-viewing-4 .navigation-block .prev-page {
  opacity: 1;
  -webkit-transform: translateY(calc(-100vh + 350px));
  transform: translateY(calc(-100vh + 350px));
}
.fp-viewing-4 .navigation-block .prev-page {
  opacity: 1;
}

.fp-viewing-5 .subscribe-page .divider {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.fp-viewing-5 .subscribe-page .menu-subscribe {
  font-weight: 600;
}
.fp-viewing-5 .navigation-block {
  bottom: -150px;
}
.fp-viewing-5 .navigation-block .prev-page {
  opacity: 1;
}
.fp-viewing-5 .navigation-block .next-page {
  opacity: 0;
}

#intro-page {
  height: 100vh;
  padding-top: 30vh;
  position: relative;
  text-align: center;
}
@media (max-width: 991px) {
  #intro-page {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20vh;
  }
}
#intro-page a {
  color: #843B03;
}
#intro-page .working-block {
  font-size: 26px;
  margin: 55px auto 0;
}
@media (max-width: 991px) {
  #intro-page .working-block {
    font-size: 22px;
    margin-top: 90px;
  }
  #intro-page .working-block .hammer {
    animation: working 1.2s linear infinite;
  }
}
@media (max-height: 650px) {
  #intro-page .working-block {
    margin-top: 30px;
  }
}
#intro-page .working-block p {
  margin-top: 25px;
}
@media (max-width: 991px) {
  #intro-page .working-block p {
    margin-top: 55px;
  }
}
#intro-page .working-block .working {
  margin: 0 auto;
  padding-left: 5px;
  position: relative;
  width: 55px;
}
#intro-page .working-block .nail {
  position: absolute;
  left: -4px;
  bottom: 0;
}
@media (max-width: 991px) {
  #intro-page #logo img {
    max-width: 100%;
  }
}

.hero-page {
  position: relative;
}
@media (max-width: 991px) {
  .hero-page {
    padding-top: 90px;
  }
}
.hero-page .header {
  position: absolute;
  transform-style: preserve-3d;
  top: 0;
}
.hero-page .header::before {
  content: "";
  background-image: linear-gradient(#FCD8C7, transparent);
  position: absolute;
  top: -50px;
  display: block;
  width: 100%;
  height: 500px;
  transform: translateZ(-1px);
}
.hero-page .slide {
  background-size: cover;
  background-position: center top;
}
@media (max-width: 991px) {
  .hero-page .slide {
    height: calc(100vh - 90px);
    width: 100%;
  }
}
.hero-page .fp-controlArrow {
  border: none;
  height: 61px;
  width: 32px;
}
.hero-page .fp-controlArrow.fp-prev {
  background-image: url("../../../images/frontend/arrow-left.png");
  left: 7vw;
}
.hero-page .fp-controlArrow.fp-next {
  background-image: url("../../../images/frontend/arrow-right.png");
  right: 7vw;
}

.info-section {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 991px) {
  .info-section {
    position: static;
  }
}
.info-section .info-bg {
  background-size: cover;
  height: calc(100vh - 245px);
  margin-left: auto;
  transition: width 1.8s ease;
  width: 0;
}
.info-section .info-bg-box {
  display: inline-block;
  height: calc(100vh - 245px);
  vertical-align: top;
}
@media (max-width: 991px) {
  .info-section .info-bg-box {
    display: none;
  }
}
.info-section .info {
  display: inline-block;
  vertical-align: top;
  margin-left: 55px;
  max-width: 430px;
}
@media (max-width: 991px) {
  .info-section .info {
    margin-left: 45px;
    margin-right: 45px;
  }
  .info-section .info p {
    margin-bottom: 24px;
  }
}
.info-section .info a {
  display: block;
}
.info-section .info a img {
  margin-right: 10px;
  vertical-align: sub;
}
.info-section .info .next-page {
  display: none;
}
@media (max-width: 991px) {
  .info-section .info .next-page {
    display: block;
    margin: 100px auto 0;
  }
}

@media (max-width: 991px) {
  .contacts-page h2 {
    margin-top: 40px;
  }
  .contacts-page p:last-of-type {
    font-size: 16px;
  }
}
.contacts-page h3 {
  margin-bottom: 3px;
}
.contacts-page h3:nth-of-type(2) {
  margin-top: 90px;
}
@media (max-width: 991px) {
  .contacts-page h3:nth-of-type(2) {
    margin-top: 35px;
  }
}
@media (max-width: 991px) {
  .contacts-page h3:last-of-type {
    font-size: 16px;
  }
}
.contacts-page .contacts-block {
  margin-top: 25px;
}
@media (max-width: 991px) {
  .contacts-page .contacts-block:last-of-type {
    font-size: 16px;
    margin-top: 30px;
  }
  .contacts-page .contacts-block:last-of-type a {
    display: inline-block;
    margin-right: 30px;
  }
}
.contacts-page .divider {
  margin-bottom: 90px;
}
@media (max-width: 991px) {
  .contacts-page .divider {
    margin-bottom: 35px;
  }
}
.contacts-page .mobile-image {
  display: none;
}
@media (max-width: 991px) {
  .contacts-page .mobile-image {
    display: block;
  }
  .contacts-page .mobile-image img {
    max-width: 100%;
  }
}

#brands_listing {
  padding: 0 45px 75px;
}
@media (max-width: 991px) {
  #brands_listing {
    margin-bottom: 50px;
  }
  #brands_listing h2 {
    padding-top: 140px;
    margin-top: 0;
  }
}
#brands_listing .row {
  -webkit-transform: translateY(500px);
  transform: translateY(500px);
  transition: transform 1.8s ease;
}
@media (max-width: 991px) {
  #brands_listing .row {
    -webkit-transform: none;
    transform: none;
  }
}
#brands_listing .divider {
  margin-bottom: 25px;
}
#brands_listing .img-fluid {
  margin-bottom: 20px;
  margin-top: 15px;
  max-height: 40px;
  max-width: 260px;
}
@media (max-width: 991px) {
  #brands_listing .img-fluid {
    cursor: pointer;
    max-width: 200px;
  }
}
#brands_listing .show-more {
  cursor: pointer;
  width: 20px;
  margin-left: 10px;
  vertical-align: baseline;
}
@media (min-width: 992px) {
  #brands_listing .show-more {
    display: none;
  }
}
#brands_listing .text {
  max-width: 450px;
}
@media (max-width: 991px) {
  #brands_listing .text {
    height: 0;
    opacity: 0;
  }
}
@media (max-width: 991px) {
  #brands_listing .col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  #brands_listing .col.open .show-more {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.5s ease;
  }
  #brands_listing .col.open .text {
    height: 100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
}

.subscribe-box {
  margin: 85px auto 0;
  max-width: 430px;
}
@media (max-width: 991px) {
  .subscribe-box {
    padding-bottom: 350px;
    margin-left: 45px;
    margin-right: 45px;
  }
  .subscribe-box h2 {
    margin-top: 145px;
  }
}
.subscribe-box .alert-danger, .subscribe-box .alert-info {
  background: none;
  border: none;
  color: #CF0000;
  margin-top: 20px;
  padding-left: 0;
}
.subscribe-box .alert-danger .close, .subscribe-box .alert-info .close {
  display: none;
}
.subscribe-box .alert-danger:before, .subscribe-box .alert-info:before {
  content: url("../../../images/frontend/error.png");
  margin-right: 5px;
  vertical-align: middle;
}
.subscribe-box .alert-success {
  background-color: #FFF;
  border: none;
  color: #843B03;
  padding: 28px 27px 38px;
  position: absolute;
  top: -60px;
  opacity: 0;
  transition: all 1s ease;
}
@media (max-width: 991px) {
  .subscribe-box .alert-success {
    top: -100px;
  }
}
.subscribe-box .alert-success::after {
  content: "";
  background-color: #FCD8C7;
  height: 50%;
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 1s ease;
  transform-origin: top;
}
.subscribe-box .alert-success::before {
  content: "";
  background-color: #FCD8C7;
  height: 50%;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: transform 1s ease;
  transform-origin: bottom;
}
.subscribe-box .alert-success.open {
  opacity: 1;
}
.subscribe-box .alert-success.open::after {
  transform: scaleY(0);
}
.subscribe-box .alert-success.open::before {
  transform: scaleY(0);
}
.subscribe-box .alert-success a {
  margin-right: 50px;
}
@media (max-width: 991px) {
  .subscribe-box .alert-success a {
    display: block;
    margin-right: 0;
  }
}
.subscribe-box .alert-success a img {
  margin-right: 8px;
  vertical-align: top;
}
.subscribe-box .alert-success h3 {
  margin-bottom: 4px;
}
.subscribe-box .alert-success p {
  margin-bottom: 23px;
}
.subscribe-box .alert-success .close {
  display: none;
}
.subscribe-box .btn {
  background-color: #EFC9B8;
  border-radius: 2px;
  color: #843B03;
  font-size: 16px;
  margin-top: 40px;
  padding: 15px 45px;
  transition: all 0.3s ease;
  width: 185px;
}
.subscribe-box .btn:hover {
  background-color: #F6D0BF;
  letter-spacing: 0.5px;
}
.subscribe-box .divider {
  margin-bottom: 145px;
}
@media (max-width: 991px) {
  .subscribe-box .divider {
    margin-bottom: 55px;
  }
}
.subscribe-box .email-input {
  border: none;
  color: #843B03;
  font-size: 16px;
  padding: 25px 17px;
}
.subscribe-box .email-input:focus {
  box-shadow: none;
}
.subscribe-box .email-input::placeholder {
  color: #EFC9B8;
  font-size: 16px;
}
.subscribe-box #newsletter_form {
  margin-top: 30px;
}
.subscribe-box #subscribers_subscribe {
  position: relative;
}

@media (max-width: 991px) {
  html:not(.open) {
    height: 100%;
    overflow-y: hidden;
  }
  html:not(.open) body {
    height: 100%;
    overflow-y: hidden;
  }
  html.open .intro-page {
    display: none;
  }
  html.open .mobile-header {
    z-index: 4;
  }
  html.open .navigation-block {
    display: none;
  }

  .owl-carousel .owl-dots {
    bottom: 30px;
  }
  .owl-carousel .owl-dots .owl-dot span {
    height: 12px;
    width: 12px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .owl-carousel .owl-dots .owl-dot.active span {
    background-color: #FCD8C7;
  }
  .owl-carousel .owl-dots .owl-dot:hover span {
    background-color: #FCD8C7;
  }

  .intro-page {
    background-color: #FCD8C7;
    position: fixed;
    top: 0;
    transition: opacity 0.3s ease;
    z-index: 3;
  }
}